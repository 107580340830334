import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import {
  noOptionsMessage,
  loadingMessage,
  imgOrganization,
  goToHref,
  domainCookie,
  dateCookie,
  loadGlobalResources,
  SetOrganizationNotification,
  SetNotificationToken,
} from "../global/GlobalTools";
import Cookies from "js-cookie";
import { MainContext } from "../../App";
import { useHistory } from "react-router-dom";

/** Services */
import {
  setCurrentOrganization,
  changeCurrentOrganization,
} from "../../api/Organizations";

//Hacer esta constante global
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "1.6091954022988506vh",
    fontFamily: "'Mulish', sans-serif",
    padding: "5px 16px 3px 16px",
    color: state.isSelected ? "white" : state.isDisabled ? "#d4d4d4" : null,
    backgroundColor: state.isSelected
      ? "var(--base-color)"
      : state.isDisabled
        ? "white"
        : state.isFocused
          ? "var(--red-light)"
          : null,
    cursor: state.isDisabled ? "not-allowed" : null,
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#ffffff",
    zIndex: "9999 !important",
    marginTop: "1px",
    width: "100%",
    borderRadius: "0 0 3px 3px",
    maxWidth: "15.625vw",
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "34.48275862068966vh",
  }),
  control: (provided) => ({
    ...provided,
    fontSize: "1.6091954022988506vh",
    fontFamily: "'Mulish', sans-serif",
  }),
  container: (provided) => ({
    ...provided,
    width: "15.625vw",
    minWidth: "15.625vh",
  }),
  input: (provided) => ({
    ...provided,
    fontSize: "1.6091954022988506vh",
    fontFamily: "'Mulish', sans-serif",
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#d4d4d4",
      fontSize: "1.6091954022988506vh",
      fontFamily: "'Mulish', sans-serif",
    };
  },
};

export default function OrganizationSearch(props) {
  const { permissions } = useContext(MainContext);
  const history = useHistory();
  const prefix = process.env.REACT_APP_PREFIX;
  const changeOrganization = (event) => {
    props.searchOrganizationToggle(false);
    //Displays the overlay that locks the screen
    props.setDisabledScreen(true);
    changeCurrentOrganization(event.value).then(() => {
      setOrganization(event.value);
      localStorage.removeItem(`cycles${prefix}`);
    });
  };

  const setOrganization = (idOrganization) => {
    setCurrentOrganization(idOrganization).then((result) => {
      localStorage.clear();
      SetNotificationToken(false);
      Cookies.remove(`cmOrganizationID${props.prefix}`, {
        domain: domainCookie(),
        path: "/",
      });

      props.setDisabledScreen(false);
      let data = result.data.data;
      //Save the organization information
      let organization = data.find(
        (organization) => organization.id === idOrganization
      );
      let orgInfoName = `cmOrganizationInfo${props.prefix}`;
      localStorage.setItem(orgInfoName, JSON.stringify(organization));
      //Change organization image url in the context
      imgOrganization(organization.logo_image_url, true);
      props.setOrganizationLogo(organization.logo_image_url);
      props.setOrganization(organization);

      //Save the selected organization
      let organizationSelected = props.options.find(
        (organization) => organization.value === idOrganization
      );
      props.setOrganizationSelected(organizationSelected);

      props.activeSubscriptions(organization.subscriptions);
      props.services(organization.services);
      props.setGoogleForEducation(
        organization.organization_info.google_for_education
      );

      //Remove cookies from cycleId (current site / previous site)
      localStorage.removeItem(`cmCycleID${props.prefix}`);
      Cookies.set(`cmCycleID${props.prefix}`, "", {
        expires: dateCookie(),
        domain: domainCookie(),
      });

      //Update the cookie organizationID (current site / previous site)
      localStorage.setItem(`cmOrganizationID${props.prefix}`, idOrganization); //Update cookie organizationID on the new site
      Cookies.set(`cmOrganizationID${props.prefix}`, idOrganization, {
        expires: dateCookie(),
        domain: domainCookie(), //Domain to Beta and Live, for local change this for localhost
      });
      // Add cookie to get plugins when changing organization
      Cookies.set(`cmChangeOrganization${props.prefix}`, 1, {
        expires: dateCookie(),
        domain: domainCookie(), //Domain to Beta and Live, for local change this for localhost
      });
      //retrieves user information
      props.getUser();
      const services = formatServices(organization.services);
      if ((services.Payments.active ||
        services.App.active ||
        services.SCE.active) &&
        permissions.admin_dashboard?.access) {
        history.push("/")
        history.push("/dashboard")
      }
      else { goToHref("/landing_page"); }
      // Function to get information general the session
      loadGlobalResources();
      SetOrganizationNotification(idOrganization);
    });
  };

  const formatServices = (orgServices) => {
    let services = {};
    for (let service of orgServices) {
      switch (service.service_id) {
        case "1": {
          //SIE
          services.SIE = {
            id: service.service_id,
            active: service.premium == 0 ? false : true,
          };
          break;
        }
        case "2": {
          //Website
          services.Website = {
            id: service.service_id,
            active: service.premium == 0 ? false : true,
          };
          break;
        }
        case "3": {
          //Payments
          services.Payments = {
            id: service.service_id,
            active: service.premium == 0 ? false : true,
          };
          break;
        }
        case "4": {
          //App
          services.App = {
            id: service.service_id,
            active: service.premium == 0 ? false : true,
          };
          break;
        }
        case "5": {
          //Reviews
          services.Reviews = {
            id: service.service_id,
            active: service.premium == 0 ? false : true,
          };
          break;
        }
        case "6": {
          //Chat
          services.Chat = {
            id: service.service_id,
            active: service.premium == 0 ? false : true,
          };
          break;
        }
        case "7": {
          //DocuSing
          services.DocuSing = {
            id: service.service_id,
            active: service.premium == 0 ? false : true,
          };
          break;
        }
        case "8": {
          //SCE
          services.SCE = {
            id: service.service_id,
            active: service.premium == 0 ? false : true,
          };
          break;
        }
        case "9": {
          //CRM
          services.CRM = {
            id: service.service_id,
            active: service.premium == 0 ? false : true,
          };
          break;
        }
        case "10": {
          //DigitalContent
          services.DigitalContent = {
            id: service.service_id,
            active: service.premium == 0 ? false : true,
          };
          break;
        }
        case "11": {
          //GoogleForEducations
          services.GoogleForEducations = {
            id: service.service_id,
            active: service.premium == 0 ? false : true,
          };
          break;
        }
      }
    }
    Cookies.set(`${props.prefix}Services`, JSON.stringify(services), {
      expires: dateCookie(),
      domain: domainCookie(), //Domain to Beta and Live, for local change this for localhost
    });
    localStorage.setItem(`${props.prefix}Services`, JSON.stringify(services));
    return services;
  };

  useEffect(() => { }, []);

  return (
    <div
      style={{
        position: "absolute",
        left: props.positionSearch,
        marginTop: "-5px",
      }}
    >
      <Select
        options={props.options}
        onChange={changeOrganization}
        placeholder={props.t("searchOrganization")}
        styles={customStyles}
        defaultMenuIsOpen
        formatOptionLabel={function (data) {
          return <span dangerouslySetInnerHTML={{ __html: data.label }} />;
        }}
        value={props.organizationSelected}
        isOptionDisabled={(option) =>
          option.value === props.organizationSelected.value
        }
        isLoading={props.options.length == 0}
        isDisabled={props.options.length === 0 || props.options.length === 1}
        closeMenuOnSelect={true}
        noOptionsMessage={noOptionsMessage}
        loadingMessage={loadingMessage}
        //menuShouldBlockScroll={props.isLocked}
        menuPortalTarget={document.body}
        //Hide menu when scrolling in main view
        closeMenuOnScroll={(e) => {
          if (
            e.target.classList &&
            e.target.classList.value &&
            e.target.classList.value != "content-table"
          ) {
            return false;
          } else {
            props.searchOrganizationToggle(false);
            return true;
          }
        }}
      />
    </div>
  );
}

OrganizationSearch.propTypes = {
  prefix: PropTypes.string,
  organization: PropTypes.object,
  setOrganization: PropTypes.func,
  chatContact: PropTypes.bool,
  //isLocked: PropTypes.bool,
  setChatContact: PropTypes.func,
  setDisabledScreen: PropTypes.func,
  setGoogleForEducation: PropTypes.func,
  options: PropTypes.array,
  activeSubscriptions: PropTypes.func,
  services: PropTypes.func,
  positionSearch: PropTypes.number,
  t: PropTypes.func,
  organizationSelected: PropTypes.object,
  setOrganizationSelected: PropTypes.func,
  searchOrganizationToggle: PropTypes.func,
  setOrganizationLogo: PropTypes.func,
  setLocked: PropTypes.func,
  isOpenSearch: PropTypes.bool,
  getUser: PropTypes.func,
};
