import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import {
  Modal,
  Button,
  Row,
  Col,
  Alert,
  Container,
  FormLabel,
} from "react-bootstrap";
import FormSelect from "../../global/form/FormSelect";
import FieldInput from "../../global/form/FieldInput";
import { Formik, Form } from "formik";

import {
  CreateDevelopmentAreas,
  UpdateDevelopmentAreas,
} from "../../../api/developmentArea";
import TextLoading from "../../global/TextLoading";

const customStyles = {
  itemList: {
    border: "1px solid gray",
    borderRadius: "5px",
    height: "40px",
  },
  iconDelete: {
    fontSize: "17px",
    color: "gray",
  },
};

export default function AddDevelopmentArea(props) {
  const [t] = useTranslation(["global", "formativeFields"]);
  const [subjects, setSubjects] = useState(props.subjects);
  const developmentAreaInfo = props.developmentAreaInfo;

  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);

  /**
   * Create or edit an element
   * @param {obj} values
   */
  const saveItem = (values) => {
    setSaving(true);
    setError(null);
    /* Format payload */
    let payload = {
      name: values.name,
      subject_ids: [],
    };
    if (values.subjects.length) {
      payload.subject_ids = values.subjects.map((subject) => subject.value);
    }
    if (props.level.type == "Basic") {
      payload.organization_school_level_cycle_id = props.level.id;
    } else {
      payload.program_school_cycle_id = props.level.id;
    }

    /* Update Development Area*/
    if (values.id) {
      payload.id = values.id;
      UpdateDevelopmentAreas(payload)
        .then(() => {})
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setSaving(false);

          props.updateTableInfo();
          props.setDevelopmentAreaInfo({ name: "", subjects: [] });
          props.setShowDevelopmentArea(false);
        });

      return;
    }
    /* Create Development Area*/
    CreateDevelopmentAreas(payload)
      .then(() => {})
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setSaving(false);
        props.updateTableInfo();
        props.setDevelopmentAreaInfo({ name: "", subjects: [] });
        props.setShowDevelopmentArea(false);
      });
  };

  return (
    <Modal
      show={props.showDevelopmentArea}
      bsSize="large"
      aria-labelledby="contained-modal-title-lg"
    >
      <Modal.Header>
        <Modal.Title className="modal-title">
          {developmentAreaInfo.id
            ? t("formativeFields:modals.titleEdit")
            : t("formativeFields:modals.titleAdd")}
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={developmentAreaInfo}
        // validationSchema={validateSchema}
        onSubmit={async (values) => {
          await new Promise((r) => setTimeout(r, 500));
          !saving ? saveItem(values) : null;
        }}
      >
        {(formik) => (
          <Form>
            <Modal.Body>
              {/* Name */}
              <Row>
                <Col md={12}>
                  <FieldInput
                    label={t("formativeFields:modals.nameOfDevelopmentField")}
                    placeholder={t(
                      "formativeFields:modals.nameDevelopmentFieldPlaceholder"
                    )}
                    name="name"
                    type="text"
                    required={true}
                    maxlength={100}
                  />
                </Col>
              </Row>

              {/* subjects Selector */}
              <Row>
                <Col md={12}>
                  <FormLabel style={{ fontWeight: "inherit" }}>
                    {t("formativeFields:modals.selectSubjectsLabel")}
                  </FormLabel>
                  <FormSelect
                    style={{ fontWeight: "inherit" }}
                    placeholder={t("formativeFields:modals.selectSubjects")}
                    name="parentCategory"
                    options={subjects.filter(
                      (subject) =>
                        formik.values &&
                        !formik.values.subjects.includes(subject)
                    )}
                    onChange={(event) => {
                      let subjectList = formik.values.subjects || [];
                      subjectList.push(event);
                      formik.setFieldValue("subjects", subjectList);
                    }}
                    value={null}
                  />
                </Col>
              </Row>
              {/* Subject selected list */}
              {formik.values.subjects.length > 0 ? (
                <>
                  <Row className="mb-1">
                    <Col md={12}>
                      <FormLabel>
                        {t("formativeFields:modals.subjects")}
                      </FormLabel>
                    </Col>
                  </Row>
                  <Container>
                    {formik.values.subjects.map((subject, index) => (
                      <Row key={index} className="mb-3">
                        <Col
                          md={11}
                          className="pt-2"
                          style={customStyles.itemList}
                        >
                          {subject.label}
                        </Col>
                        <Col md={1} className="pt-2">
                          <i
                            className="bi bi-dash-circle-fill"
                            style={customStyles.iconDelete}
                            role="button"
                            onClick={() => {
                              let subjectList = formik.values.subjects;
                              subjectList.splice(index, 1);
                              formik.setFieldValue("subjects", subjectList);

                              // Eval if exists in the global list
                              let indexFound = subjects.findIndex(
                                (sub) => sub.value === subject.value
                              );
                              if (indexFound == -1) {
                                const updateList = subjects;
                                updateList.push(subject);
                                setSubjects(updateList);
                              }
                            }}
                          />
                        </Col>
                      </Row>
                    ))}
                  </Container>
                </>
              ) : (
                <Row>
                  <Col md={12} className="text-center">
                    {t("formativeFields:modals.noSubjectsSelected")}
                  </Col>
                </Row>
              )}

              {error && (
                <Row>
                  <Col md={12}>
                    <Alert variant="warning">{error}</Alert>
                  </Col>
                </Row>
              )}
            </Modal.Body>
            <Modal.Footer
              style={
                developmentAreaInfo.id
                  ? { justifyContent: "space-between" }
                  : {}
              }
            >
              {developmentAreaInfo.id && (
                <div className="float-start">
                  <Button
                    className="float-start"
                    variant="outline-secondary"
                    onClick={() => {
                      props.setSelectedRowIds([developmentAreaInfo.id]);
                      props.setShowDeleteDevelopmentArea(true);
                      props.setShowDevelopmentArea(false);
                      props.setDevelopmentAreaInfo({ name: "", subjects: [] });
                    }}
                    disabled={saving}
                  >
                    {t("global:buttons.eliminate")}
                  </Button>
                </div>
              )}
              <div>
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    props.setDevelopmentAreaInfo({ name: "", subjects: [] });
                    props.setShowDevelopmentArea(false);
                  }}
                  disabled={saving}
                >
                  {t("global:buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  disabled={saving || formik.values.name === ""}
                >
                  {saving ? (
                    <TextLoading
                      text={t("global:buttons.saving")}
                      variant="light"
                    />
                  ) : developmentAreaInfo.id ? (
                    t("global:buttons.save")
                  ) : (
                    t("global:buttons.add")
                  )}
                </Button>
              </div>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

AddDevelopmentArea.propTypes = {
  showDevelopmentArea: PropTypes.bool, //Required
  setShowDevelopmentArea: PropTypes.func, //Required
  developmentAreaInfo: PropTypes.object,
  setShowDeleteDevelopmentArea: PropTypes.func,
  subjects: PropTypes.array,
  level: PropTypes.object,
  updateTableInfo: PropTypes.func,
  setDevelopmentAreaInfo: PropTypes.func,
  setSelectedRowIds: PropTypes.func,
};
