import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import routes from "./routes";
export const MainContext = React.createContext();
import { GetPermissions } from "./api/Session";
import Cookies from "js-cookie";
import * as Sentry from "@sentry/react";
import {
  loadGlobalResources,
  setCookie,
} from "./components/global/GlobalTools";

//Create permissions variable
const prefix = process.env.REACT_APP_PREFIX;
let permissionsDefault = {};
if (
  localStorage.getItem(`cmPermissions${prefix}`) &&
  localStorage.getItem(`cmPermissions${prefix}`) !== "undefined"
) {
  if (
    Cookies.get(`cmToken${prefix}`) === localStorage.getItem(`cmToken${prefix}`)
  ) {
    permissionsDefault = JSON.parse(
      localStorage.getItem(`cmPermissions${prefix}`)
    );
  }
}

// Clean and update Storage
localStorage.clear();
//Read cookies and store them in localStorage
let getSiteCookies = Cookies.get(); //Obtains cookies from the domain .campusmovil.com
let keysCookies = Object.keys(getSiteCookies);
let valuesCookies = Object.values(getSiteCookies);
keysCookies.map((key, index) => {
  //Save cookies in localStorage
  localStorage.setItem(key, valuesCookies[index]);
});

//Set user data when send exception to sentry
Sentry.setUser({
  id: Cookies.get(`cmUserID${prefix}`), //user id
  group: Cookies.get(`cmGroup${prefix}`), //user group
  organization: Cookies.get(`cmOrganizationID${prefix}`), //user organization id
});

// Get User Token
const CookieToken = Cookies.get(`cmToken${prefix}`);
if (CookieToken) {
  loadGlobalResources(CookieToken);
} else {
  // Get pathname
  const pathname = window.location.pathname;
  // Get all the parameters of the url
  const urlParams = window.location.search;
  // Save cookie with pathname
  if (pathname && typeof pathname == "string" && pathname.length > 0) {
    const redirectionPathname = {
      pathname: pathname,
      params: urlParams,
      version: 2,
    };
    setCookie("RedirectionPathname", JSON.stringify(redirectionPathname));
  }
}

function App() {
  const [permissions, setPermissions] = useState(permissionsDefault);
  /**
   * updates the global permissions variable as well as updates the localStorage
   * @param {object} updatedPermissions
   */
  const updatePermissions = (updatedPermissions) => {
    localStorage.setItem(
      `cmPermissions${prefix}`,
      JSON.stringify(updatedPermissions)
    );
    setPermissions(updatedPermissions);
  };
  // if it does not have the permissions object it will try to get them based on the user token
  if (Object.entries(permissions).length === 0) {
    GetPermissions(Cookies.get(`cmToken${prefix}`)).then((result) => {
      updatePermissions(result.data);
    });
  }
  return (
    // The application is governed by permissions, until they are available, it will be possible to render
    Object.entries(permissions).length !== 0 && (
      <MainContext.Provider value={{ permissions, updatePermissions }}>
        <Router>
          <div>
            <Switch>
              {routes.map((route, i) => (
                <RouteWithSubRoutes key={i} {...route} />
              ))}
            </Switch>
          </div>
        </Router>
      </MainContext.Provider>
    )
  );
}

// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.
function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={(props) => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />
      )}
    />
  );
}

export default App;
