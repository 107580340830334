import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import PropTypes from "prop-types";
import {
  Alert,
  Button,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { TwitterPicker } from "react-color";
import { useTranslation } from "react-i18next";

// services
import {
  CreateSubject,
  DeleteSubjects,
  UpdateSubject,
} from "../../../api/class";
import { getColors } from "../../global/GlobalTools";
import TextLoading from "../../global/TextLoading";

const colors = getColors();
export default function ModalCreateSubject(props) {
  const [t] = useTranslation(["global", "class"]);
  const [subject, setSubject] = useState(
    props.subjectToEdit || {
      name: "",
      description: "",
      color: "#fedae3",
    }
  );
  const [activeBtn, setActiveBtn] = useState(!!props.subjectToEdit);
  const [colorPiker, setSolorPiker] = useState(false);
  const [error, setError] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  /**
   *  Method to change the values of subject
   * @param {obj} event
   */
  const changeValues = (event) => {
    let subjectUpdated = { ...subject };
    let field = event.target.id;
    let value = event.target.value;
    subjectUpdated[field] = value;
    setSubject(subjectUpdated);
    let formReady =
      subjectUpdated.name != "" && subjectUpdated.description != "";
    setActiveBtn(formReady);
  };

  /**
   * Lever to show or hide the color picker
   */
  const toggleSketchPicker = () => {
    setSolorPiker(!colorPiker);
  };

  /**
   * Retrieve and save the hexadecimal value of the selected color
   * @param {obj} color
   */
  const selectSubjectColor = (color) => {
    let subjectUpdated = { ...subject };
    subjectUpdated.color = color.hex;
    setSubject(subjectUpdated);
    setSolorPiker(false);
  };

  /**
   * Method for requesting the API to create a subject
   */
  const createSubject = () => {
    let request = {
      school_cycle_id: props.schoolCycle.id,
      name: subject.name,
      description: subject.description,
      color: subject.color,
    };
    if (props.schoolLevel.id == "higherLevel") {
      request.program_id = props.academicOffert.value;
    } else {
      request.organization_school_level_id = props.schoolLevel.value;
    }

    CreateSubject(request)
      .then((result) => {
        if (result && result.description) {
          Sentry.captureException(Error(JSON.stringify(result)));
          setError(result.description);
          return;
        }
        props.refreshList(result.data);
        props.setShowCreateSubject(false);
      })
      .finally(() => {
        setIsLoadingButton(false);
      });
  };

  /**
   * Removes subject by id
   */
  const deleteSubject = () => {
    const request = {
      subject_ids: [props.subjectToEdit.index],
    };

    DeleteSubjects(request)
      .then((result) => {
        if (result && result.description) {
          Sentry.captureException(Error(JSON.stringify(result)));
          setError(result.description);
        } else {
          props.setShowCreateSubject(false);
          props.refreshList({
            id: props.subjectToEdit.index,
            remove: true,
          });
        }
      })
      .finally(() => {
        setIsLoadingDelete(false);
      });
  };

  /**
   * Method for requesting the API to update a subject
   */
  const updateSubject = () => {
    let request = {
      subject_id: subject.index,
      school_cycle_id: props.schoolCycle.id,
      name: subject.name,
      description: subject.description,
      color: subject.color,
    };
    if (props.schoolLevel.id == "higherLevel") {
      request.program_id = props.academicOffert.value;
    } else {
      request.organization_school_level_id = props.schoolLevel.value;
    }
    UpdateSubject(request)
      .then((result) => {
        if (result && result.description) {
          Sentry.captureException(Error(JSON.stringify(result)));
          setError(result.description);
          return;
        }
        props.refreshList(result.data);
        props.setShowCreateSubject(false);
      })
      .finally(() => {
        setIsLoadingButton(false);
      });
  };

  useEffect(() => {
    Sentry.setTag("section", "Create Subject Modal");
  }, []);

  /**
   * removes one or more subjects by id
   */
  return (
    <Modal
      show={props.showCreateSubject}
      bsSize="large"
      aria-labelledby="contained-modal-title-lg"
    >
      <Modal.Header>
        <Modal.Title className="modal-title">
          {props.subjectToEdit?.index
            ? t("class:modalCreateSubject.editSubject")
            : t("class:modalCreateSubject.title")}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col md={12}>
            <Form.Group className="mb-3">
              <Form.Label>
                {t("class:modalCreateSubject.subjectName")}
              </Form.Label>
              <Form.Control
                id="name"
                type="text"
                placeholder={t("class:modalCreateSubject.subjectName")}
                value={subject.name}
                onChange={changeValues}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form.Group className="mb-3">
              <Form.Label>
                {t("class:modalCreateSubject.subjectDescription")}
              </Form.Label>
              <Form.Control
                id="description"
                as="textarea"
                type="text"
                placeholder={t("class:modalCreateSubject.subjectDescription")}
                value={subject.description}
                onChange={changeValues}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Label htmlFor="colorIcon">
              {t("class:modalCreateSubject.subjectColor")}
            </Form.Label>
            <InputGroup className="mb-3" id="colorIcon">
              <InputGroup.Text
                role="button"
                style={{ background: subject.color, width: "35px" }}
                onClick={toggleSketchPicker}
              ></InputGroup.Text>
              <Form.Control
                role="button"
                placeholder="Username"
                aria-label="Username"
                aria-describedby="basic-addon1"
                readOnly={true}
                onClick={toggleSketchPicker}
                value={subject.color}
              />
            </InputGroup>
          </Col>
          {colorPiker && (
            <TwitterPicker
              color={subject.color}
              colors={colors}
              onChangeComplete={selectSubjectColor}
            />
          )}
        </Row>
        {/* Show errors when creating or editing a matter */}
        {error && (
          <div className="mtop20">
            <Alert variant="warning">{error}</Alert>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "space-between" }}>
        <div>
          {props.subjectToEdit?.index && (
            <Button
              onClick={() => {
                setIsLoadingDelete(true);
                deleteSubject();
              }}
              disabled={isLoadingDelete}
              className="float-start"
            >
              {isLoadingDelete ? (
                <TextLoading
                  text={t("class:modalCreateSubject.deletingSubject")}
                  variant="light"
                />
              ) : (
                t("class:modalCreateSubject.deleteSubject")
              )}
            </Button>
          )}
        </div>
        <div className="float-end">
          <Button
            variant="outline-secondary"
            onClick={() => props.setShowCreateSubject(false)}
          >
            {t("global:buttons.cancel")}
          </Button>
          <Button
            disabled={!activeBtn || isLoadingButton}
            onClick={() => {
              setIsLoadingButton(true);
              if (props.subjectToEdit?.index) {
                updateSubject();
              } else {
                createSubject();
              }
            }}
          >
            {props.subjectToEdit?.index &&
              (isLoadingButton ? (
                <TextLoading
                  text={t("class:modalCreateSubject.editingSubject")}
                  variant="light"
                />
              ) : (
                t("class:modalCreateSubject.editSubject")
              ))}
            {!props.subjectToEdit?.index &&
              (isLoadingButton ? (
                <TextLoading
                  text={t("class:modalCreateSubject.creatingSubject")}
                  variant="light"
                />
              ) : (
                t("class:modalCreateSubject.title")
              ))}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

ModalCreateSubject.propTypes = {
  showCreateSubject: PropTypes.bool,
  setShowCreateSubject: PropTypes.func,
  schoolCycle: PropTypes.object,
  schoolLevel: PropTypes.object,
  academicOffert: PropTypes.object,
  refreshList: PropTypes.func,
  subjectToEdit: PropTypes.object,
};
