import { TokenDestroy, GetPermissions } from "../../api/Session";
import { GetHideMenus, goToHref } from "./GlobalTools";
import Cookies from "js-cookie";

const prefix = process.env.REACT_APP_PREFIX;
export function ValidateSection(permissions, view) {
  try {
    let access = false;
    const hideMenus = parseInt(GetHideMenus()) === 1;
    const sectionToShow = ["payments", "perfil"];
    if (hideMenus && !sectionToShow.includes(view)) {
      return false;
    }
    switch (view) {
      case "chat":
        access =
          permissions.chat_config.access || permissions.chat_support.access;
        break;
      case "settingsCM":
        access =
          permissions.admin_users.access ||
          permissions.admin_organizations.access ||
          permissions.admin_services.access ||
          permissions.admin_permissions.access;
        break;
      case "notifications":
        access = permissions.notifications.access;
        break;
      case "google":
        access = permissions.google.access;
        break;
      case "perfil":
        access = permissions.user_profile.access;
        break;

      case "app":
        access =
          permissions.app_extra_links.access ||
          permissions.app_other_tools.access;
        break;
      case "crm":
        access =
          permissions.crm_tasks.access ||
          permissions.crm_prospects.access ||
          permissions.crm_activities.access;
        break;
      case "payments":
        access =
          permissions.payments_cashier.access ||
          permissions.payments_account_status.access ||
          permissions.payments_transactions.access ||
          permissions.payments_debts.access ||
          permissions.payments_incomes.access ||
          permissions.payments_subconcepts.access ||
          permissions.payments_cards.access ||
          permissions.payments_students_scholarships.access ||
          permissions.payments_scholarships.access;
        break;
      case "students":
        access =
          permissions.students_list.access ||
          permissions.students_basic_info.access ||
          permissions.students_classes.access ||
          permissions.students_charges.access ||
          permissions.students_medical_info.access ||
          permissions.students_relatives.access ||
          permissions.students_invoicing.access ||
          permissions.student_generate_credentials.access;
        break;
      case "family":
        access = permissions.relatives.access;
        break;
      case "events":
        access = permissions.events_list.access;
        break;
      case "news":
        access =
          permissions.news.access ||
          permissions.news_categories.access ||
          permissions.news_labels.access ||
          permissions.news_ticker.access;
        break;
      case "classes":
        access =
          permissions.classes.access || permissions.classes_subjects.access;
        break;
      case "reviews":
        access = permissions.reviews.access;
        break;
      case "personal":
        access = permissions.staff_list.access;
        break;
      case "reports":
        access = permissions.reports.access;
        break;
      case "website":
        access = permissions.website.access;
        break;
      case "configurations":
        access =
          permissions.config_cycle.access ||
          permissions.config_organization.access ||
          permissions.config_report_cards.access;
        break;
      case "trainings":
        access = permissions.tutorials.access;
        break;
      case "dashboard":
        access = permissions.admin_dashboard.access;
        break;
      default:
        access = false;
    }
    return access;
  } catch (error) {
    console.error("some required permissions are missing");
    localStorage.removeItem(`cmPermissions${prefix}`);
    GetPermissions(Cookies.get(`cmToken${prefix}`)).then((result) => {
      if (result.data === "undefined") {
        TokenDestroy();
        if (location.href.indexOf("/login") == -1) {
          goToHref("/login");
        }
      }
      localStorage.setItem(
        `cmPermissions${prefix}`,
        JSON.stringify(result.data)
      );
      location.reload();
    });
  }
}
