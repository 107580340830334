import React, { useEffect, useState } from "react";
import {
  Col,
  Dropdown,
  FormCheck,
  FormControl,
  FormGroup,
  FormLabel,
  OverlayTrigger,
  Row,
  Tab,
  Table,
  Tabs,
  Tooltip,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DateView from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import {
  CreateMultipleAttendance,
  CreateStudentComment,
  CreateStudentScore,
  UpdateStudentScore,
  getClassList,
} from "../../api/class";
import Cookies from "js-cookie";
import { GetStudentsClass } from "../../api/Dashboard";
import TextLoading from "../global/TextLoading";
import PropTypes from "prop-types";
import { validateUserRoleIds } from "../global/GlobalTools";

const prefix = process.env.REACT_APP_PREFIX;
const customStyles = {
  checkBox: {
    width: "16px",
    height: "16px",
  },
  studentsContainer: {
    maxHeight: "190px",
    overflowY: "auto",
    overflowX: "hidden",
  },
  subTable: {
    maxHeight: "160px",
    overflowY: "auto",
    overflowX: "hidden",
  },
};
const lazyLoadingTable = () => {
  return (
    <div>
      <Row className="table-lazyLoading mt-1">
        <Col md={12}>
          <div style={customStyles.lazy}></div>
        </Col>
      </Row>
      <Row className="table-lazyLoading mt-1">
        <Col md={12}>
          <div style={customStyles.lazy}></div>
        </Col>
      </Row>
      <Row className="table-lazyLoading mt-1">
        <Col md={12}>
          <div style={customStyles.lazy}></div>
        </Col>
      </Row>
      <Row className="table-lazyLoading mt-1">
        <Col md={12}>
          <div style={customStyles.lazy}></div>
        </Col>
      </Row>
    </div>
  );
};
//create array with students data to show in the table
const dummyData = [
  {
    id: 1,
    name: "Tamara Perez",
    program_name: "Preparatoria",
    program_level: "1",
    grade: "1",
    group: "A",
    grading_periods: [
      {
        id: 1,
        score: "10",
        comment: "Muy bien",
      },
      {
        id: 2,
        score: "9",
        comment: "Bien",
      },
      {
        id: 3,
        score: "8",
        comment: "Regular",
      },
    ],
  },
  {
    id: 2,
    name: "Maria Lopez",
    program_name: "Preparatoria",
    program_level: "1",
    grade: "1",
    group: "A",
    grading_periods: [
      {
        id: 1,
        score: "10",
        comment: "Muy bien",
      },
      {
        id: 2,
        score: "9",
        comment: "Bien",
      },
      {
        id: 3,
        score: "8",
        comment: "Regular",
      },
    ],
  },
];

const StudentsAttendance = (props) => {
  const [tab, setTab] = useState("attendace");
  const [date, setDate] = useState(new Date());
  const [classes, setClasses] = useState([]);
  const [classSelected, setClassSelected] = useState(false);
  const [periods, setPeriods] = useState([]);
  const [periodSelected, setPeriodSelected] = useState(false);
  const [loadingStudents, setLoadingStudents] = useState(false);
  const [studentsClass, setStudentsClass] = useState([]);
  const [allStudentsSelected, setAllStudentsSelected] = useState(false);
  const [loadingClass, setLoadingClass] = useState(true);
  const [t] = useTranslation(["dashboard"]);
  registerLocale("spanish", es);

  /**
   * Description: Function to get the list of classes
   * @param {int} cycleId
   */
  const getClasses = (cycleId) => {
    const userID = Cookies.get(`cmUserID${prefix}`);
    const request = {
      school_cycle_id: cycleId,
      teacher_id: userID,
      additional_fields: "score_config",
    };
    getClassList(request)
      .then((result) => {
        let classes = result?.data?.data || [];
        classes.forEach((element) => {
          element.label = element.class_title || element.subject_name;
          element.value = element.id;
        });
        setClasses(classes);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoadingClass(false);
      });
  };

  /**
   * Description: Function to get students with attendance according to date
   * @param {int} classId
   * @param {string} date
   */
  const getStudentsClass = (classId, date) => {
    setLoadingStudents(true);
    const request = {
      class_id: classId,
      attendance_date: getDateInUnix(date),
    };
    GetStudentsClass(request)
      .then((result) => {
        if (result.status) {
          let periods = [];
          const students = result.data;
          const hasStudents = students.length;
          students.forEach((student) => {
            student.hasAttendance = student.attendance?.value === "1";
          });
          setStudentsClass(students);
          // Update periods
          if (hasStudents) {
            periods = students[0].grading_periods.map((period, index) => {
              return {
                value: period.id,
                label: index + 1,
                index,
              };
            });
          }
          setPeriods(periods);
          // Know if all students have attendance on the selected date
          const allHasAttendance =
            hasStudents && students.every((student) => student.hasAttendance);
          setAllStudentsSelected(allHasAttendance);
          // Loading completed successfully
          setLoadingStudents(false);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {});
  };

  /**
   * Description: Function to update the attendance of all students in the class.
   */
  const selectAllStudents = () => {
    const students = [...studentsClass];
    // update the value of attendance for all students locally
    students.forEach((student) => {
      student.hasAttendance = !allStudentsSelected;
    });
    setStudentsClass(students);
    setAllStudentsSelected(!allStudentsSelected);
    // update the value of attendance for all students in the database
    updateStudentsAttendance(!allStudentsSelected);
  };

  /**
   * Description: Function to update the attendance of a student in the class.
   * @param {int} studentId
   * @param {boolean} checked
   */
  const selectStudent = (studentId, checked) => {
    const students = [...studentsClass];
    // locally update a student's attendance
    const studentIndex = students.findIndex(
      (student) => student.id == studentId
    );
    students[studentIndex].hasAttendance = checked;
    setStudentsClass(students);
    // Know if all students are selected
    const allStudentsSelected =
      students.length && students.every((student) => student.hasAttendance);
    setAllStudentsSelected(allStudentsSelected);
    // Update attendance in the db
    updateStudentsAttendance(checked, [studentId]);
  };

  /**
   * Description: Function to update student attendance
   * @param {boolean} attendace
   * @param {array} studentIds
   */
  const updateStudentsAttendance = (attendace, studentIds) => {
    const value = attendace ? "1" : "0";
    const request = {
      class_id: classSelected.value,
      date: getDateInUnix(),
      value,
    };
    if (studentIds) {
      request.student_ids = studentIds;
    }
    CreateMultipleAttendance(request)
      .then(() => {})
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {});
  };

  /**
   * Description: Function to get a date in UNIX format
   * @param {string} dateParam
   * @return {unix} Timestamp
   */
  const getDateInUnix = (dateParam) => {
    const newDate = dateParam || date;
    const dateString = newDate.toDateString();
    const dateUnixMiliseconds = new Date(dateString).getTime();
    const dateUnixSeconds = dateUnixMiliseconds / 1000;
    return dateUnixSeconds;
  };

  /**
   * Description: Function to change value for score or comment
   * @param {string} property score|comment
   * @param {string} value
   * @param {string} studentClassId
   */
  const changeStudentGrades = (property, value, studentClassId) => {
    //identify the student and the period
    const scoreConfig = classSelected.score_config;
    const students = [...studentsClass];
    const studentIndex = students.findIndex(
      (student) => student.student_class_id === studentClassId
    );
    const student = students[studentIndex];
    const studentPeriodIndex = student.grading_periods.findIndex(
      (period) => period.id === periodSelected.value
    );
    if (property === "score") {
      //validations for score input
      if (scoreConfig.score_system === "customized") {
        const regexLetter = /^([A-F]|[a-f])$/;
        if (regexLetter.test(value) || value === "") {
          student.grading_periods[studentPeriodIndex][property] = value;
          students[studentIndex] = student;
        }
      } else {
        const regexInteger = /^([0-9]|[1-9][0-9]|100)$/;
        const regexDecimals =
          scoreConfig.grading_precision == "1"
            ? /^(\d{0,2}(\.\d{0,1})?|100(\.00?)?)$/
            : /^(\d{0,2}(\.\d{0,2})?|100(\.00?)?)$/;
        const regexNumeric =
          scoreConfig.grading_period_number_format === "integer"
            ? regexInteger
            : regexDecimals;
        if (regexNumeric.test(value) || value === "") {
          student.grading_periods[studentPeriodIndex][property] = value;
        }
      }
    } else {
      //add comment to student
      student.grading_periods[studentPeriodIndex][property] = value;
    }
    students[studentIndex] = student;
    setStudentsClass(students);
  };

  /**
   * Description: Function to set student grade
   * @param {string} score
   * @param {string} studentClassId
   * @param {string} scoreId
   */
  const setStudentGrade = (score, studentClassId, scoreId, studentIndex) => {
    const studentScore = {
      student_class_id: studentClassId,
      grading_period_id: periodSelected.value,
    };
    score ? (studentScore.score = score) : null;
    // If has score then update else create new score
    if (scoreId) {
      studentScore.id = scoreId;
      updateStudentScore(studentScore, studentIndex);
    } else {
      createStudentScore(studentScore, studentIndex);
    }
  };

  /**
   * Description: Function to create a student's score
   * @param {object} request
   */
  const createStudentScore = (request, studentIndex) => {
    CreateStudentScore(request)
      .then((response) => {
        if (response.status) {
          let students = [...studentsClass];
          students[studentIndex].average = response.data.average;
          setStudentsClass(students);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {});
  };

  /**
   * Description: Function to update a student's score
   * @param {object} request
   */
  const updateStudentScore = (request, studentIndex) => {
    UpdateStudentScore(request)
      .then((response) => {
        if (response.status) {
          let students = [...studentsClass];
          students[studentIndex].average = response.data.average;
          setStudentsClass(students);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {});
  };

  /**
   * Description: Function to create a student's observation
   * @param {string} comment
   * @param {string} studentClassId
   */
  const createStudentObservation = (comment, studentClassId) => {
    const request = {
      comment,
      grading_period_id: periodSelected.value,
      student_class_id: studentClassId,
    };
    CreateStudentComment(request)
      .then(() => {})
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {});
  };

  /**
   * Description: Function to obtain the percentage of the width of the columns
   * @param { array } [periods=[]]
   * @returns
   */
  const obtainPorsentage = (periods = []) => {
    const number = periods.length + 1;
    const portengaeBase = 65;
    return portengaeBase / number + "%";
  };

  /**
   * Initial loading
   */
  useEffect(() => {
    if (props.active == false) {
      setStudentsClass(dummyData);
      setClassSelected(true);
      setLoadingClass(false);
    } else {
      getClasses();
    }
  }, [props.active]);

  return (
    <>
      {/** Title and Filters*/}
      <Row className="mt-3">
        {/** Title */}
        <Col xs={12} md={5} lg={6} xl={8}>
          <i className="bi bi-briefcase me-2 fs-6" />
          <b className="fs-6">{t("textStudents.title")}</b>
        </Col>
        {/** Filters */}
        <Col xs={12} md={7} lg={6} xl={4} className="d-flex flex-row-reverse">
          {tab === "scores" ? (
            <FormGroup>
              <FormLabel className="mb-0">
                {t("textStudents.period")}:
              </FormLabel>
              <Dropdown>
                <Dropdown.Toggle
                  className="text-truncate"
                  id="periods"
                  variant="outline-secondary"
                  disabled={!classSelected}
                >
                  {periodSelected?.label
                    ? t("textStudents.period") + ` ${periodSelected.label}`
                    : t("textStudents.allPeriods")}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {periods.map((period) => {
                    return (
                      <Dropdown.Item
                        key={period.value}
                        onClick={() => setPeriodSelected(period)}
                      >
                        {t("textStudents.period")}
                        {" " + period.label}
                      </Dropdown.Item>
                    );
                  })}
                  <Dropdown.Item
                    key={"allPeriods"}
                    onClick={() => setPeriodSelected(false)}
                  >
                    {t("textStudents.allPeriods")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </FormGroup>
          ) : (
            <FormGroup>
              <FormLabel className="mb-0">{t("textStudents.date")}:</FormLabel>
              <div className="me-0">
                <DateView
                  selected={date}
                  onChange={(e) => {
                    setDate(e);
                    getStudentsClass(classSelected.value, e);
                  }}
                  dateFormat="dd/MM/yyyy"
                  locale="spanish"
                  className="inputDatePicker"
                  disabled={!classSelected}
                />
              </div>
            </FormGroup>
          )}
          <FormGroup className="me-3">
            <FormLabel className="mb-0">{t("textStudents.class")}: </FormLabel>
            <Dropdown>
              <Dropdown.Toggle
                className="text-truncate"
                id="classes"
                variant="outline-secondary"
                disabled={loadingClass}
              >
                {loadingClass ? (
                  <TextLoading text={t("textStudents.selectClass")} />
                ) : (
                  classSelected?.label || t("textStudents.selectClass")
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {classes.map((item) => {
                  return (
                    <Dropdown.Item
                      key={item.value}
                      onClick={() => {
                        setPeriodSelected(false);
                        setClassSelected(item);
                        getStudentsClass(item.value);
                      }}
                      className="text-truncate"
                    >
                      {item.label}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </FormGroup>
        </Col>
      </Row>
      {/** Tabs with tables */}
      <Row className="mt-0">
        {/** Tabs */}

        <Col>
          <Tabs
            defaultActiveKey={tab}
            id="uncontrolled-tab-example"
            onSelect={(tab) => setTab(tab)}
          >
            <Tab eventKey="attendace" title={t("textStudents.attendance")}>
              {loadingStudents ? (
                lazyLoadingTable()
              ) : classSelected ? (
                <div>
                  <Table
                    bordered
                    responsive
                    style={{
                      borderTop: "white",
                      borderBottom: "solid 1px #dee2e6",
                    }}
                  >
                    <thead
                      className="mt-2 mb-2"
                      style={{ borderBottom: "solid 1px #dee2e6" }}
                    >
                      <tr>
                        <th width="89.5%">{t("textStudents.studentName")}</th>
                        <th width="10%">
                          <div className="d-flex justify-content-center">
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip
                                  className="color-white"
                                  id="selectAllClasses"
                                >
                                  {t("textStudents.selectAll")}
                                </Tooltip>
                              }
                            >
                              <FormCheck
                                aria-label="selectAllStudents"
                                checked={allStudentsSelected}
                                onChange={selectAllStudents}
                                style={customStyles.checkBox}
                                disabled={!studentsClass.length}
                              />
                            </OverlayTrigger>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ borderTop: "1px" }}>
                      <tr>
                        <td colSpan="2" style={{ padding: 0 }}>
                          <div
                            style={customStyles.subTable}
                            className="scrollbar-hidden"
                          >
                            <Table
                              bordered
                              responsive
                              style={{ marginBottom: 0 }}
                            >
                              <tbody>
                                {studentsClass.map((student) => {
                                  return (
                                    <tr
                                      key={student.id}
                                      className="mb-2"
                                      style={{ borderWidth: "0px 0" }}
                                    >
                                      <td
                                        width="90%"
                                        style={{ borderLeft: "none" }}
                                      >
                                        {/* If the user is a teacher, they can access the student profile from the dashboard */}
                                        {validateUserRoleIds(7) ? (
                                          <a
                                            href={`${process.env.REACT_APP_V1}/estudiante/${student.id}`}
                                          >
                                            {`${student.name} - `}
                                            {student.program_name
                                              ? `(${student.program_name} ${student.program_level})`
                                              : `(${student.grade}${student.group})`}
                                          </a>
                                        ) : (
                                          `${student.name} - ` +
                                          (student.program_name
                                            ? `(${student.program_name} ${student.program_level})`
                                            : `(${student.grade}${student.group})`)
                                        )}
                                      </td>
                                      <td
                                        width="10%"
                                        style={{ borderRight: "none" }}
                                      >
                                        <div className="d-flex justify-content-center">
                                          <FormCheck
                                            aria-label="selectStudent"
                                            checked={student.hasAttendance}
                                            onChange={() =>
                                              selectStudent(
                                                student.id,
                                                !student.hasAttendance
                                              )
                                            }
                                            style={customStyles.checkBox}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>{" "}
                </div>
              ) : (
                <p className="d-flex justify-content-center mt-5">
                  <b style={{ color: "#aaaaaa" }}>
                    {t("textStudents.noClassSelected")}
                  </b>
                </p>
              )}
            </Tab>
            <Tab eventKey="scores" title={t("textStudents.grades")}>
              {loadingStudents ? (
                lazyLoadingTable()
              ) : classSelected ? (
                periodSelected ? (
                  /** Grade table for the selected period */
                  <div>
                    <Table
                      bordered
                      responsive
                      style={{
                        borderTop: "white",
                        borderBottom: "solid 1px #dee2e6",
                      }}
                    >
                      <thead style={{ borderBottom: "solid 1px #dee2e6" }}>
                        <tr>
                          <th width="35%">{t("textStudents.studentName")}</th>
                          <th width="20%" className="text-center">
                            {t("textStudents.grade")}
                          </th>
                          <th width="45%" className="text-center">
                            {t("textStudents.observations")}
                          </th>
                        </tr>
                      </thead>
                      <tbody style={{ borderTop: "1px" }}>
                        <tr>
                          <td colSpan="3" style={{ padding: 0 }}>
                            <div
                              style={customStyles.subTable}
                              className="scrollbar-hidden"
                            >
                              <Table
                                bordered
                                responsive
                                style={{ marginBottom: 0 }}
                              >
                                <tbody>
                                  {studentsClass.map(
                                    (student, studentIndex) => {
                                      return (
                                        <tr key={student.id} className="mb-2">
                                          <td
                                            width="35%"
                                            style={{ verticalAlign: "middle" }}
                                          >
                                            {`${student.name} - `}
                                            {student.program_name
                                              ? `(${student.program_name} ${student.program_level})`
                                              : `(${student.grade}${student.group})`}
                                          </td>
                                          <td
                                            width="20%"
                                            style={{ textAlign: "center" }}
                                          >
                                            {classSelected.score_config
                                              .score_system === "customized" ? (
                                              <Dropdown
                                                id="score"
                                                onSelect={(value) => {
                                                  value =
                                                    value != 0 ? value : null;
                                                  const studentsTemp = [
                                                    ...studentsClass,
                                                  ];
                                                  studentsTemp[
                                                    studentIndex
                                                  ].grading_periods[
                                                    periodSelected.index
                                                  ].score = value;
                                                  setStudentsClass(
                                                    studentsTemp
                                                  );
                                                  setStudentGrade(
                                                    value,
                                                    student.student_class_id,
                                                    student.grading_periods[
                                                      periodSelected.index
                                                    ].score_id,
                                                    studentIndex
                                                  );
                                                }}
                                              >
                                                <Dropdown.Toggle
                                                  variant="outline-secondary"
                                                  className="text-truncate"
                                                >
                                                  {student.grading_periods[
                                                    periodSelected.index
                                                  ].score || ""}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu
                                                  style={{ minWidth: "50px" }}
                                                >
                                                  <Dropdown.Item
                                                    className="removeElementDropdown"
                                                    eventKey={0}
                                                    style={{
                                                      color: "red",
                                                      textAlign: "center",
                                                      fontSize: "14px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    X
                                                  </Dropdown.Item>
                                                  {classSelected.score_config.equivalences.map(
                                                    (equivalence) => {
                                                      return (
                                                        <Dropdown.Item
                                                          key={equivalence.id}
                                                          eventKey={
                                                            equivalence.score_letter
                                                          }
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          {
                                                            equivalence.score_letter
                                                          }
                                                        </Dropdown.Item>
                                                      );
                                                    }
                                                  )}
                                                </Dropdown.Menu>
                                              </Dropdown>
                                            ) : (
                                              <FormControl
                                                className="border-0 text-center"
                                                id={`score${student.id}`}
                                                onBlur={(e) =>
                                                  setStudentGrade(
                                                    e.target.value,
                                                    student.student_class_id,
                                                    student.grading_periods[
                                                      periodSelected.index
                                                    ].score_id,
                                                    studentIndex
                                                  )
                                                }
                                                onChange={(e) =>
                                                  changeStudentGrades(
                                                    "score",
                                                    e.target.value,
                                                    student.student_class_id
                                                  )
                                                }
                                                onKeyDown={(e) => {
                                                  if (e.key === "Enter") {
                                                    const inputSelected =
                                                      document.getElementById(
                                                        `score${student.id}`
                                                      );
                                                    inputSelected.blur();
                                                  }
                                                }}
                                                value={
                                                  student.grading_periods[
                                                    periodSelected.index
                                                  ].score || ""
                                                }
                                              />
                                            )}
                                          </td>
                                          <td width="45%">
                                            <FormControl
                                              className="border-0"
                                              id="observation"
                                              onBlur={(e) =>
                                                createStudentObservation(
                                                  e.target.value,
                                                  student.student_class_id
                                                )
                                              }
                                              onChange={(e) =>
                                                changeStudentGrades(
                                                  "comment",
                                                  e.target.value,
                                                  student.student_class_id
                                                )
                                              }
                                              value={
                                                student.grading_periods[
                                                  periodSelected.index
                                                ].comment || ""
                                              }
                                              maxLength={
                                                periods.length > 10
                                                  ? "150"
                                                  : "250"
                                              }
                                            />
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  /** Grade table for all periods */
                  <div>
                    <Table
                      bordered
                      responsive
                      style={{
                        borderTop: "white",
                        borderBottom: "solid 1px #dee2e6",
                      }}
                    >
                      <thead style={{ borderBottom: "solid 2px #dee2e6" }}>
                        <tr>
                          <th width="35%">{t("textStudents.studentName")}</th>
                          {periods.map((period) => {
                            return (
                              <th
                                key={period.value}
                                className="text-center"
                                width={obtainPorsentage(
                                  studentsClass[0]?.grading_periods
                                )}
                              >
                                {t("textStudents.period")}
                                {" " + period.label}
                              </th>
                            );
                          })}
                          <th
                            width={obtainPorsentage(
                              studentsClass[0]?.grading_periods
                            )}
                          >
                            {t("textStudents.averageGeneral")}
                          </th>
                        </tr>
                      </thead>
                      <tbody style={{ borderTop: "1px" }}>
                        <tr>
                          <td
                            colSpan={
                              (studentsClass[0]?.grading_periods?.length || 0) +
                              2
                            }
                            style={{ padding: 0 }}
                          >
                            <div
                              style={customStyles.subTable}
                              className="scrollbar-hidden"
                            >
                              <Table
                                bordered
                                responsive
                                style={{
                                  marginBottom: 0,
                                  tableLayout: "fixed",
                                }}
                              >
                                <tbody>
                                  {studentsClass.map((student) => {
                                    return (
                                      <tr key={student.id} className="mb-2">
                                        <td
                                          width="35%"
                                          style={{ borderLeft: "none" }}
                                        >
                                          {`${student.name} - `}
                                          {student.program_name
                                            ? `(${student.program_name} ${student.program_level})`
                                            : `(${student.grade}${student.group})`}
                                        </td>
                                        {student.grading_periods.map(
                                          (period) => {
                                            return (
                                              <td
                                                key={period.id}
                                                className="text-center"
                                                width={obtainPorsentage(
                                                  student.grading_periods
                                                )}
                                              >
                                                {period.score}
                                              </td>
                                            );
                                          }
                                        )}
                                        <td
                                          width={obtainPorsentage(
                                            student.grading_periods
                                          )}
                                          style={{ borderRight: "none" }}
                                        >
                                          {student.average}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                )
              ) : (
                <p className="d-flex justify-content-center mt-5">
                  <b style={{ color: "#aaaaaa" }}>
                    {t("textStudents.noClassSelected2")}
                  </b>
                </p>
              )}
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </>
  );
};

export default StudentsAttendance;
StudentsAttendance.propTypes = {
  active: PropTypes.bool,
};
