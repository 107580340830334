/**
 *       **** Readme ****
 *
 * Component: <ContentTable />
 * Purpose: Styles for the report table.
 *
 * Props:
 * startColumFilter: Value number, Column index at which filters start
 * lastColumFilter: Value number, Column index where the filters end
 *
 * Creation date: 06/December/2021
 * Last update: 17/December/2021
 */

import styled from "styled-components";
import {} from "react-bootstrap";

const ContentTable = styled.div`
  /** Button **/
  div.content-header {
    button {
      background-color: #ffffff;
      border: 1px solid #d6dadb;
      border-radius: 8px;
      font-family: var(--font-family-mulish);
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      letter-spacing: -0.01em;
      color: #2d2d2d;
      min-height: 380px;
    }
  }

  div.content-table {
    border-radius: 10px !important;
    background-color: #ffffff;
    outline: auto;
    outline-color: #d8d8d8;
    outline-width: 2px;
    padding-top: 15px;
    display: block;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    min-height: 380px;
    max-height: ${window.innerHeight -250}px;
  }

  table {
    border-spacing: 0;
    border-radius: 10px !important;
    border-collapse: collapse;
    width: 100%;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
      :first-child {
      }
    }

    td {
      padding: 0.5rem;
      word-break: break-word;
    }

    th,
    td {
      margin: 0;
      font-family: var(--font-family-mulish);
      :last-child {
        border-right: 0;
      }
    }
    thead {
      display:table;
	    width:100%;
      width: calc(100% - 15px);
      tr {
        border-bottom: 2px solid #d8d8d8;
      }

      th {
        padding-left: 0;
        padding-right: 0;
        font-weight: 700;
        font-size: 12px;
        text-align: center;
        line-height: 17px;
        color: #4d4d4d;

        div.row-select-all {
          width: fit-content;
          float: right;
          margin-top: -11px;
        }

        .row-select-all + .arrow-sorted {
          display: none !important;
        }

        :first-child {
          padding-left: 30px;
        }

        :last-child {
          padding-right: 30px;
        }

        :first-child .element-filter {
          display: none;
        }

        p.title-header {
          text-align: left;
          padding-left: 6px;
          padding-top: 3px;
          padding-bottom: 3px;
          /*width: inherit;*/
          width: 100%;
        }

        .settings-table {
          position: absolute;
          top: 3px;
          right: 0;
          font-size: 16px;
          cursor: pointer;
          width: 35px;
          height: 29px;
          padding-top: 2px;
        }

        .settings-table i:hover {
          padding: 4px 8px;
          background: var(--red-light);
          border-radius: 4px;
        }

        .settings-table + .title-header {
          display: none;
        }
      }

      th input {
        border: 1px solid #dddddd;
        border-left: 0 solid transparent;
        padding: 3px 4px;
        color: #4d4d4d;
        height: 29px;
        /*width: 100%;
         min-width: max-content;*/
        width: inherit;

        ::placeholder {
          color: #d6dadb;
          font-size: 14px;
        }

        :focus-visible {
          outline: none;
        }
      }

      th:first-child input {
        padding: 5px 7px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-left-color: #dddddd;
      }

      th div.element-filter {
        height: 29px !important;
        /*width: inherit;*/
        width: 100%;
      }

      th div.element-filter .filter-input {
        width: inherit;
      }

      th div.element-summation {
        height: 40px;
        padding-top: 5px;
        line-height: 40px;
        font-size: 12px;
        color: #575757;
        padding-left: 5px;
        padding-right: 5px;
        /*width: max-content;*/
        font-weight: 700;
        width: inherit;
        text-align: left;
      }

      th:nth-child(${(props) => props.startColumFilter + 2})
        div.element-filter {
        position: relative;
        /*width: inherit;*/
        width: 100%;
      }

      th:nth-child(${(props) => props.startColumFilter + 2})
        div.element-filter
        input {
        font-family: var(--font-family-mulish);
        padding: 3px 4px;
        height: 29px;
        /*width: 100% !important;
         min-width: 100px !important;*/
        /*width: inherit;*/
        width: 100%;
      }

      th:nth-child(${(props) => props.startColumFilter + 2})
        div.element-filter:after {
        content: url("/img/table/filter.svg");
        position: absolute;
        width: 35px;
        height: 29px;
        background-color: #626262;
        top: 0;
        left: -35px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        line-height: 29px;
        padding-top: 4px;
      }

      th:nth-child(${(props) => props.lastColumFilter + 2}) input {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        width: inherit;
      }
    }

    tbody {
      display:block;
      width:100%;
      min-height:510px;
      max-height: ${window.innerHeight-366}px;
      overflow:auto;
      overflow-x:hidden;
    }

    tbody tr {
      color: #575757;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      width:100%;
      display:table;
      table-layout:fixed;

      td div.row-select {
        width: fit-content;
        margin-right: -8px;
      }

      :nth-child(odd) {
        background-color: #fff0f3;
        border-top: 1px solid #dee2e6;
        border-bottom: 1px solid #dee2e6;
      }

      :nth-child(even) {
        background-color: #fff;
      }
      :hover {
        background: #ffd9e0;
      }

      :last-child {
        border-bottom: 1px solid transparent;
    }
  }
`;

export default ContentTable;
