import axios from "axios";
import { ErrorResponse, validateErrorResponse } from "./AxiosExtension";

const prefix = process.env.REACT_APP_PREFIX;

export const GetDevelopmentAreas = async (data) => {
  let axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/development_areas`;
  axiosOptions.params = data;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

export const CreateDevelopmentAreas = async (data) => {
  let axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/development_areas`;
  axiosOptions.data = data;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

export const UpdateDevelopmentAreas = async (data) => {
  let axiosOptions = {};
  axiosOptions.method = "put";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/development_areas`;
  axiosOptions.data = data;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

export const DeleteDevelopmentAreas = async (data) => {
  let axiosOptions = {};
  axiosOptions.method = "delete";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/development_areas`;
  axiosOptions.data = data;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};