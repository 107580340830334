import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Button, Dropdown } from "react-bootstrap";
import * as moment from "moment";
import { GetSubjects } from "../../api/class";

// Custom Components
import ContentSolid from "../global/ContentSolid";
import { LoadingTable } from "../lazyLoading/LazyLoading";
import ContentTable from "../table/ContentTable";
import { Table } from "../table/Table";

import AddDevelopmentArea from "./modals/AddDevelopmentArea";
import DeleteDevelopmentArea from "./modals/DeleteDevelopmentArea";

// API services
import { GetDevelopmentAreas } from "../../api/developmentArea";

//Custom styles section
const stylesCustom = {
  textDangerBold: {
    color: "#ea2c54",
    fontWeight: "bold",
    fontSize: "small",
  },
  textGrayBold: {
    color: "#c8cbcc",
    fontWeight: "bold",
    fontSize: "small",
  },
};

const FormativeFields = () => {
  const [t] = useTranslation(["global", "formativeFields"]);
  const componentRef = useRef(); //Create the reference for printing

  const prefix = process.env.REACT_APP_PREFIX;
  const [schoolCycles, setSchoolCycles] = useState([]);
  const [schoolCycle, setSchoolCycle] = useState(null);
  const [schoolLevel, setSchoolLevel] = useState(null);
  const [schoolLevels, setSchoolLevels] = useState([]);
  const [count, setCount] = useState(0);
  const [schoolPrograms, setschoolPrograms] = useState([]);
  const [academicOffert, setAcademiOffert] = useState(null);
  const [subjects, setSubjects] = useState([]);
  const [developmentAreas, setDevelopmentAreas] = useState([]);
  // Table elements
  const [loadingView, setLoadingView] = useState(true);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [selectAllRows, setSelectAllRows] = useState({
    switch: false,
    value: false,
  });
  const [loading, setLoading] = useState(true);
  //Modals
  const [showDevelopmentArea, setShowDevelopmentArea] = useState(false);
  const [developmentAreaInfo, setDevelopmentAreaInfo] = useState({
    name: "",
    subjects: [],
  });
  const [showDeleteDevelopmentArea, setShowDeleteDevelopmentArea] =
    useState(false);

  const columns = React.useMemo(
    () => [
      {
        Header: t("formativeFields:main.nameOfTrainingField"),
        accessor: "name",
        width: 540,
      },
      {
        Header: t("formativeFields:main.totalOfSubjects"),
        accessor: "subjectsCount",
        width: 540,
      },
    ],
    [loadingView]
  );

  // Saves the data for the table in memory, and is not updated unless useMemo identifies a change in the data
  const data = React.useMemo(() => developmentAreas, [loading]);

  /**
   * Purpose: Get the school cycles of the school
   */
  const obtainSchoolCycles = () => {
    let cyclesLocal = JSON.parse(localStorage.getItem(`cycles${prefix}`)) || [];
    const hasCycles =
      cyclesLocal && Array.isArray(cyclesLocal) && cyclesLocal.length;
    if (hasCycles) {
      setSchoolCycles(cyclesLocal);
      let currentCycle = false;
      const today = moment().unix();
      for (let cycle of cyclesLocal) {
        if (
          today > cycle.start_time &&
          today < cycle.end_time &&
          cycle.current_cycle == 1
        ) {
          currentCycle = cycle;
        }
      }
      currentCycle = currentCycle || cyclesLocal[0];
      // Get current school cycle
      if (currentCycle) {
        //Get School Levels}
        getLevels(currentCycle);
        setSchoolCycle(currentCycle);
      }
      setLoadingView(false);
    } else {
      setTimeout(() => {
        obtainSchoolCycles();
      }, 1000);
    }
  };

  /**
   * Obtains the school levels (School levels) of the selected School cycle.
   * @param {Id} schoolCycleId
   */
  const getLevels = (schoolCycle) => {
    // Filtering school cycles levels
    let schoolLevels = [];
    //Check if the school cycle has higher education
    const programs = schoolCycle.colleges_and_schools
      .map((item) => {
        let values = [];
        for (let schoolLevel of item.school_levels) {
          for (let program of schoolLevel.programs) {
            if (program.enabled == "1") {
              program.label = program.name;
              program.value = program.id;
              program.programSchoolCycleId = program.program_school_cycle_id;
              values.push(program);
            }
          }
        }
        return values;
      })
      .flat();
    // Get school cycle levels from school cycle
    const schoolCycleLevels = schoolCycle.school_levels;
    if (schoolCycleLevels && schoolCycleLevels.length != 0) {
      for (let level of schoolCycleLevels) {
        // Get grades grups
        let gradeGroups = [];
        if (level.grades && level.grades.length !== 0) {
          const grades = level.grades;
          for (let grade of grades) {
            if (grade.groups && grade.groups.length !== 0) {
              const groups = grade.groups;
              groups.forEach((group) => {
                const item = {
                  id: group.grade_group_grade_level_id,
                  value: group.grade_group_grade_level_id,
                  label: `${grade.grade_level} ${group.name}`,
                };
                gradeGroups.push(item);
              });
            }
          }
        }
        // Get school level item
        schoolLevels.push({
          id: level.id,
          value: level.organization_school_level_id,
          label: level.school_level_name,
          grades: gradeGroups,
        });
      }
    }
    // Add higher education option
    if (programs.length) {
      setschoolPrograms(programs);
      schoolLevels.push({
        id: "higherLevel",
        value: "higherLevel",
        label: t("class:modalImport.higherLevel"),
      });
    }
    if (schoolLevels && schoolCycleLevels.length !== 0) {
      setSchoolLevel(schoolLevels[0]);
      getSubjects(schoolCycle.id, schoolLevels[0]);
      getDevelopmentAreas(schoolLevels[0], "Basic");
    } else if (programs.length) {
      setSchoolLevel(schoolLevels[0]);
      setAcademiOffert(programs[0]);
      getSubjects(schoolCycle.id, programs[0], "higherLevel");
      getDevelopmentAreas(programs[0], "higherLevel");
    }
    setSchoolLevels(schoolLevels);
  };

  /**
   * Puporse: Get initial Subjects values
   * @param {Number} schoolCycleId
   */
  const getSubjects = (schoolCycleId, schoolLevel = null, level = "Basic") => {
    const values = {};
    if (schoolCycleId) values.school_cycle_id = schoolCycleId;
    if (schoolLevel) {
      switch (level) {
        case "Basic":
          values.organization_school_level_id = schoolLevel.value;
          break;
        case "higherLevel":
          values.program_id = schoolLevel.value;
          break;
        default:
          break;
      }
    }
    GetSubjects(values)
      .then((result) => {
        if (result && result.data) {
          let data = result.data;
          data = data.filter((subject) => subject.development_area_id == null);
          const subjects = data.map((subject) => {
            return {
              value: subject.id,
              label: subject.name,
              development_area_id: subject.development_area_id,
            };
          });
          setSubjects(subjects);
        }
      })
      .catch()
      .finally();
  };

  /**
   * Modal launcher to edit a developmentArea
   * @param {obj} item
   */
  const editDevelopmentArea = (item) => {
    const developmentArea = { ...item };
    // format subjects
    developmentArea.subjects = developmentArea.subjects.map((subject) => {
      return {
        value: subject.id,
        label: subject.name,
      };
    });
    setDevelopmentAreaInfo(developmentArea);
    setShowDevelopmentArea(true);
  };

  /**
   * Purpose: Function to select or deselect all records
   */
  const selectAllRecords = () => {
    let selectAll = {
      switch: !selectAllRows.switch,
      value: !selectAllRows.value,
    };
    setSelectAllRows(selectAll);
  };

  /**
   * Purpose: Get the development areas of the selected level
   * @param {obj} level
   * @param {string} type
   */
  const getDevelopmentAreas = (level, type) => {
    setLoading(true);
    const payload = {};
    if (type == "Basic") {
      payload.organization_school_level_cycle_id = level?.id || schoolLevel.id;
    } else {
      payload.program_school_cycle_id =
        level?.program_school_cycle_id ||
        academicOffert.program_school_cycle_id;
    }

    GetDevelopmentAreas(payload)
      .then((result) => {
        if (result && result.data) {
          for (let item of result.data) {
            item.index = item.id;
            item.subjectsCount = item.subjects.length;
          }
          setDevelopmentAreas(result.data);
        }
      })
      .catch()
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   * Purpose: Refresh the table information when a CRUD action is performed
   */
  const updateTableInfo = () => {
    setLoading(true);
    const levelTag = schoolLevel.id == "higherLevel" ? "higherLevel" : "Basic";
    const filterLevel =
      levelTag == "higherLevel" ? academicOffert : schoolLevel;
    getDevelopmentAreas(
      filterLevel,
      schoolLevel.id == "higherLevel" ? "higherLevel" : "Basic"
    );
    getSubjects(schoolCycle.id, filterLevel, levelTag);
  };

  /**
   * Initial Loading
   */
  useEffect(() => {
    obtainSchoolCycles();
  }, []);

  return (
    <Container fluid className="mt-3">
      <Row className="mb-3">
        <Col md={9}>
          {/* Delete selected items button */}
          {selectedRowIds.length > 0 && (
            <Button
              variant="outline-secondary"
              className="me-3"
              onClick={() => setShowDeleteDevelopmentArea(true)}
            >
              {t("class:main.delete")}
            </Button>
          )}
          {/** Filter Select School Cycle */}
          <Dropdown style={{ display: "inline-block" }}>
            <Dropdown.Toggle
              id="downloadOptionCycle"
              variant="outline-secondary"
              disabled={loading}
            >
              {schoolCycle?.label || t("cycles:select.placeholder")}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {schoolCycles.map((cycle) => {
                return (
                  <Dropdown.Item
                    key={cycle.value}
                    onClick={() => {
                      setSchoolLevel(null);
                      // Get School levels
                      getLevels(cycle);
                      setSchoolCycle(cycle);
                    }}
                  >
                    {cycle.label}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
          {/**schoolLevels */}
          <Dropdown className="ms-3" style={{ display: "inline-block" }}>
            <Dropdown.Toggle
              id="downloadOptionSchoolLevels"
              variant="outline-secondary"
              disabled={loading}
            >
              {schoolLevel?.label || t("class:modalImport.selectSchoolLevel")}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {schoolLevels.map((schoolLevel) => {
                return (
                  <Dropdown.Item
                    key={schoolLevel.value}
                    onClick={() => {
                      setSchoolLevel(schoolLevel);
                      const newLevelTag =
                        schoolLevel.id == "higherLevel"
                          ? "higherLevel"
                          : "Basic";
                      if (schoolLevel.id == "higherLevel") {
                        getDevelopmentAreas(schoolPrograms[0], newLevelTag);
                        setAcademiOffert(schoolPrograms[0]);
                        getSubjects(
                          schoolCycle.id,
                          schoolPrograms[0],
                          "higherLevel"
                        );
                      } else {
                        getDevelopmentAreas(schoolLevel, newLevelTag);
                        getSubjects(schoolCycle.id, schoolLevel);
                      }
                    }}
                  >
                    {schoolLevel.label}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
          {/** higherLevel*/}
          {schoolLevel && schoolLevel.id == "higherLevel" && (
            <Dropdown className="ms-3" style={{ display: "inline-block" }}>
              <Dropdown.Toggle
                id="downloadOptionSchoolLevels"
                variant="outline-secondary"
                disabled={loading}
              >
                {academicOffert?.label ||
                  t("class:modalImport.academicOffering")}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {schoolPrograms.map((offer) => {
                  return (
                    <Dropdown.Item
                      key={offer.value}
                      onClick={() => {
                        setAcademiOffert(offer);
                        getDevelopmentAreas(offer, "higherLevel");
                        getSubjects(schoolCycle.id, offer, "higherLevel");
                      }}
                    >
                      {offer.label}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          )}
          {/* Total counter of records and selected records in Subjects table */}
          {selectedRowIds.length ? (
            selectedRowIds.length == 1 ? (
              <p className="ms-3 d-inline" style={stylesCustom.textDangerBold}>
                {t("table:textTableViews.selectedRecord", {
                  countSelected: selectedRowIds.length,
                })}
              </p>
            ) : (
              <p className="ms-3 d-inline" style={stylesCustom.textDangerBold}>
                {t("table:textTableViews.selectedRecords", {
                  countSelected: selectedRowIds.length,
                })}
              </p>
            )
          ) : (
            <p className="ms-3 d-inline" style={stylesCustom.textGrayBold}>
              {t("table:textTableViews.totalRecords", {
                count: count,
              })}
            </p>
          )}
        </Col>
        <Col md={3}>
          <Button
            variant="primary"
            className="float-end"
            onClick={() => setShowDevelopmentArea(true)}
          >
            {t("formativeFields:main.createDevelopmentAreas")}
          </Button>
        </Col>
      </Row>
      {/* Table */}
      {loadingView ? (
        <ContentSolid>
          <LoadingTable />
        </ContentSolid>
      ) : (
        <ContentTable className="mt-3" startColumFilter={1} lastColumFilter={4}>
          <Table
            ref={componentRef}
            columns={columns}
            data={data}
            // setExportData={setExportData}
            // setPrintExport={setPrintExport}
            rowSelect={true}
            setSelectedRowIds={setSelectedRowIds}
            // setIsAllRowsSelected={setIsAllRowsSelected}
            selectAllRows={selectAllRows}
            rowOnclick={editDevelopmentArea}
            setRowsDisplayed={setCount}
            enableCheckToSelectAllRecords={true}
            limitRowsShown={50}
            selectAllRecords={selectAllRecords}
            loading={loading}
          ></Table>
        </ContentTable>
      )}

      {/* Modals  */}
      {showDevelopmentArea && (
        <AddDevelopmentArea
          showDevelopmentArea={showDevelopmentArea}
          setShowDevelopmentArea={setShowDevelopmentArea}
          developmentAreaInfo={developmentAreaInfo}
          setShowDeleteDevelopmentArea={setShowDeleteDevelopmentArea}
          subjects={subjects}
          updateTableInfo={updateTableInfo}
          setDevelopmentAreaInfo={setDevelopmentAreaInfo}
          setSelectedRowIds={setSelectedRowIds}
          level={{
            id:
              schoolLevel?.id == "higherLevel"
                ? academicOffert.program_school_cycle_id
                : schoolLevel.id,
            type: schoolLevel?.id == "higherLevel" ? "higherLevel" : "Basic",
          }}
        />
      )}
      {showDeleteDevelopmentArea && (
        <DeleteDevelopmentArea
          showDeleteDevelopmentArea={showDeleteDevelopmentArea}
          setShowDeleteDevelopmentArea={setShowDeleteDevelopmentArea}
          selectedRowIds={selectedRowIds}
          updateTableInfo={updateTableInfo}
        />
      )}
    </Container>
  );
};

export default FormativeFields;
