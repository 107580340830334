import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal, Row, Button, Col, FormLabel, Alert } from "react-bootstrap";

//validation resources
import { Form, Formik } from "formik";
import * as Yup from "yup";
import "yup-phone";
// services
import {
  getTimesZone as timesZone,
  CreateOrganization,
  AddOrganizationService,
  GetGroupOfOrganization,
} from "../../api/Organizations";
import { getCountries, getStates, getCities } from "../../api/Countries";

import FieldInput from "../global/form/FieldInput";
import FormSelect from "../../components/global/form/FormSelect";
import FormCheck from "../global/form/FormCheck";
import TextLoading from "../global/TextLoading";
import ContentButton from "../global/ContentButton";
import FormAsyncSelect from "../global/form/FormAsyncSelect";

const organization = {
  name: "",
  time_zone_id: "", //is automatically filled
  planTypeId: 9,
  phone: "",
  email: "",
  country: "",
  state: "",
  city: "",
  zipCode: "",
  address: "",
  facebook: "",
  twitter: "",
  google_analytics_id: "",
  google_for_education: {}, //google servives
  firebase_project_name: "",
  language: {},
  testing: false,
};

export default function ModalCreateOrganization(props) {
  const [t] = useTranslation([
    "global",
    "organizations",
    "modalCreateStudent",
    "address",
  ]);
  const [timeZoneId, setTimeZoneId] = useState(0);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [serviceErrors, setServiceErrors] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);
  const [selectedGroupOrganization, setSelectedGroupOrganization] =
    useState(null);
  const [typingCallback, setTypingCallback] = useState(null);
  const [groupsOrganizations, setGroupsOrganizations] = useState([]);

  /**
   * get time zone catalog
   */
  const getTimesZone = () => {
    timesZone().then((response) => {
      const timesZoneList = response.data;
      const myTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const timeZoneId = timesZoneList.find(
        (item) => item.description === myTimeZone
      ).id;
      setTimeZoneId(timeZoneId);
    });
  };

  /**
   * get countries catalog
   */
  const getCountriesList = () => {
    getCountries().then((response) => {
      const countries = response.data.map((country) => {
        return { id: country.id, value: country.id, label: country.name };
      });
      setCountries(countries);
    });
  };

  /**
   * get state catalog
   * @param {int} countryId
   */
  const getStateList = (countryId) => {
    getStates({ countryId: countryId }).then((result) => {
      const states = result.data.map((state) => {
        return {
          id: state.id,
          value: state.id,
          label: state.name,
        };
      });
      setStates(states);
    });
  };

  /**
   * get cities catalog
   * @param {int} cityId
   */
  const getCitiesList = (cityId) => {
    getCities({ stateId: cityId, limit: 0 }).then((result) => {
      const cities = result.data.map((city) => {
        return {
          id: city.id,
          value: city.id,
          label: city.name,
        };
      });
      setCities(cities);
    });
  };

  /**
   * method for creating an organization
   * @param {*} values
   */
  const createOrganization = (values) => {
    setLoadingAction(true);
    const dataForServices = { ...values };
    const request = formatRequest(values);
    CreateOrganization(request).then((result) => {
      setLoadingAction(false);
      if (result.error) {
        setServiceErrors(result.error.description);
        return;
      }
      props.updateList();
      props.setShowModalCreateOrganization(false);
      addServicesOrganization(dataForServices, result.data[0].id);
    });
  };

  /**
   * Add selected services to the organization
   * @param {object} dataForServices
   * @param {int} organizationID
   */
  const addServicesOrganization = (dataForServices, organizationID) => {
    // extract only the service parameters
    const services = [];
    for (let param in dataForServices) {
      if (param.includes("service") && dataForServices[param]) {
        services.push(
          organizationServices.find((service) => service.name == param)
        );
      }
    }
    if (services.length) {
      services.forEach((service) => {
        let payload = {
          subscription_id: service.id,
          organization_id: organizationID,
        };
        AddOrganizationService(payload).then(() => {});
      });
    }
  };

  /**
   * structure the request for the creation of an organization
   * @param {obj} values
   * @returns obj
   */
  const formatRequest = (values) => {
    values = purgeObject(values);
    let organizationInfo = {
      name: values.name,
      time_zone_id: timeZoneId,
      organization_plan_type_id: values.planTypeId,
      testing: values.testing ? 1 : 0,

      firebase_project_name: values.firebase_project_name,
      google_analytics_id: values.google_analytics_id,
      google_for_education: values.google_for_education.value ? 1 : 0,
      language: values.language.value || "spanish",
    };

    if (values.country.value) {
      organizationInfo.country_id = values.country.value;
    }
    if (values.state.value) {
      organizationInfo.state_id = values.state.value;
    }
    if (values.city.value) {
      organizationInfo.city_id = values.city.value;
    }
    if (values.organization_group_id) {
      organizationInfo.organization_group_id = values.organization_group_id;
    }

    // Contact information
    let orgContactInfo = {};
    orgContactInfo.address_1 = values.address;
    orgContactInfo.email = values.email;
    orgContactInfo.phone = values.phone;
    orgContactInfo.zip = values.zipCode;

    if (values.facebook || values.twitter) {
      orgContactInfo.social_media = [];
      if (values.facebook) {
        orgContactInfo.social_media.push({
          type: "facebook",
          url: values.facebook,
        });
      }
      if (values.twitter) {
        orgContactInfo.social_media.push({
          type: "twitter",
          url: values.twitter,
        });
      }
    }
    organizationInfo = purgeObject(organizationInfo);
    organizationInfo.org_contact_info = orgContactInfo;
    return organizationInfo;
  };

  /**
   * Only removes empty fields
   * @argument {JSON object} object
   * @returns {JSON object}
   */
  const purgeObject = (object) => {
    for (var field in object) {
      //look for empty fields within arrays
      if (typeof object[field] === "object" && object[field] != null) {
        let array = object[field];
        for (let i = 0; i < array.length; i++) {
          let item = array[i];
          for (let field_item in item) {
            if (item[field_item] === "" || item[field_item] == null) {
              delete item[field_item];
            }
          }
        }
      }
      if (
        (object[field] === "" || object[field] == null) &&
        field !== "class_title" &&
        field !== "class_room"
      ) {
        delete object[field];
      }
    }
    return object;
  };

  /**
   * Description: Search and get the groups of organizations
   * @param {String} text
   * @param {Function} callback
   */
  const searchgroupOfOrganizations = (text, callback) => {
    if (typingCallback) {
      clearTimeout(typingCallback);
    }
    let typing = setTimeout(() => {
      GetGroupOfOrganization(text)
        .then((result) => {
          const groupOfOrganizations = result.data;
          let formatGroupOfOrganizations = [];
          const notSelectGroupOfOrganizations = {
            label: t("organizations:modalCreate.notSelectGroupOfOrganizations"),
            value: "",
          };
          formatGroupOfOrganizations.push(notSelectGroupOfOrganizations);
          if (groupOfOrganizations && groupOfOrganizations.length) {
            for (let groupOfOrganization of groupOfOrganizations) {
              let formatGroupOfOrg = {
                label: groupOfOrganization.name,
                value: groupOfOrganization.id,
              };
              formatGroupOfOrganizations.push(formatGroupOfOrg);
            }
            setGroupsOrganizations(formatGroupOfOrganizations);
            callback(formatGroupOfOrganizations);
          } else {
            callback([]);
          }
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    }, 500);
    setTypingCallback(typing);
  };

  /**
   * Initial loading
   */
  useEffect(() => {
    getTimesZone();
    getCountriesList();
    searchgroupOfOrganizations();
  }, []);

  // Form validation schemes
  const validateOrganization = Yup.object().shape({
    name: Yup.string().required(
      t("organizations:modalCreate.organizationName") +
        " " +
        t("global:isRequired")
    ),
    phone: Yup.string().required(
      t("organizations:modalCreate.phone") + " " + t("global:isRequired")
    ),
    email: Yup.string()
      .required(
        t("organizations:modalCreate.email") + " " + t("global:isRequired")
      )
      .email(t("global:validations.invalidEmailFormat")),
    country: Yup.object().required(
      t("organizations:modalCreate.country") + " " + t("global:isRequired")
    ),
    city: Yup.object().required(
      t("address:select.titleMunicipality") + " " + t("global:isRequired")
    ),
    state: Yup.object().required(
      t("organizations:modalCreate.state") + " " + t("global:isRequired")
    ),
    zipCode: Yup.number().required(
      t("organizations:modalCreate.zipCode") + " " + t("global:isRequired")
    ),
    address: Yup.string().required(
      t("organizations:modalCreate.address") + " " + t("global:isRequired")
    ),
    facebook: Yup.string().test(
      "verificationFacebook",
      t("global:validations.invalidFacebookFormat"),
      (facebook) => {
        return !facebook || (facebook && facebook.includes("facebook.com"));
      }
    ),
    twitter: Yup.string().test(
      "verificationTwitter",
      t("global:validations.invalidTwitterFormat"),
      (twitter) => {
        return !twitter || (twitter && twitter.includes("twitter.com"));
      }
    ),
  });

  // Services available to organizations
  const organizationServices = [
    {
      id: 1,
      label: t("organizations:modalCreate.class"),
      name: "serviceClass",
    },
    {
      id: 2,
      label: t("organizations:modalCreate.events"),
      name: "serviceEvents",
    },
    {
      id: 3,
      label: t("organizations:modalCreate.students"),
      name: "serviceStudents",
    },
    {
      id: 4,
      label: t("organizations:modalCreate.staff"),
      name: "serviceStaff",
    },
    {
      id: 5,
      label: t("organizations:modalCreate.website"),
      name: "serviceWebSite",
    },
    {
      id: 6,
      label: t("organizations:modalCreate.reviews"),
      name: "serviceReviews",
    },
    {
      id: 7,
      label: t("organizations:modalCreate.payments"),
      name: "servicePaymenst",
    },
    {
      id: 10,
      label: t("organizations:modalCreate.chat"),
      name: "serviceChat",
    },
  ];

  return (
    <div>
      <Modal
        show={props.showModalCreateOrganization}
        aria-labelledby="contained-modal-title-md"
        size="md"
      >
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title text-center">
            {t("organizations:view.createOrganization")}
          </Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={organization}
          validationSchema={validateOrganization}
          onSubmit={(values) => {
            createOrganization(values);
          }}
        >
          {({ setFieldValue, values }) => (
            <Fragment>
              <Form>
                <Modal.Body className="modal-body">
                  {/* organizationName */}
                  <Row>
                    <Col md={12}>
                      <FieldInput
                        label={t("organizations:modalCreate.organizationName")}
                        name="name"
                        type="text"
                        placeholder={t(
                          "organizations:modalCreate.organizationName"
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <FormAsyncSelect
                        label={t(
                          "organizations:modalCreate.organizationGroupSelector"
                        )}
                        name="organization_group_id"
                        defaultOptions={groupsOrganizations}
                        loadOptions={
                          searchgroupOfOrganizations
                            ? searchgroupOfOrganizations
                            : null
                        }
                        value={selectedGroupOrganization}
                        onChange={(event) => {
                          setSelectedGroupOrganization(event);
                          setFieldValue(
                            "organization_group_id",
                            event && event.value ? event.value : ""
                          );
                        }}
                        placeholder={t(
                          "organizations:modalCreate.placeholderSelectGroupOfOrganizations"
                        )}
                      />
                    </Col>
                  </Row>
                  {/* phone || email */}
                  <Row>
                    <Col md={6}>
                      <FieldInput
                        label={t("organizations:modalCreate.phone")}
                        name="phone"
                        type="phone"
                        placeholder={t("organizations:modalCreate.phone")}
                        maxlength="10"
                      />
                    </Col>
                    <Col md={6}>
                      <FieldInput
                        label={t("organizations:modalCreate.email")}
                        name="email"
                        type="email"
                        placeholder={t("organizations:modalCreate.email")}
                      />
                    </Col>
                  </Row>
                  {/* country || state */}
                  <Row>
                    <Col md={6}>
                      <FormSelect
                        label={t("organizations:modalCreate.country")}
                        name="country"
                        options={countries}
                        onChange={(country) => {
                          setFieldValue("country", country);
                          setFieldValue("state", "");
                          setFieldValue("city", "");
                          setCities([]);
                          getStateList(country.id);
                        }}
                        value={values.country.id ? values.country : null}
                        placeholder={t("address:select.selectCountry")}
                        required={true}
                      />
                    </Col>
                    <Col md={6}>
                      <FormSelect
                        label={t("organizations:modalCreate.state")}
                        name="state"
                        options={states}
                        onChange={(state) => {
                          setFieldValue("state", state);
                          setFieldValue("city", "");
                          getCitiesList(state.id);
                        }}
                        value={values.state.id ? values.state : null}
                        placeholder={t("address:select.placeholderState")}
                        isDisabled={!states.length}
                        required={true}
                      />
                    </Col>
                  </Row>
                  {/*  city || zipCode */}
                  <Row>
                    <Col md={6}>
                      <FormSelect
                        label={t("address:select.titleMunicipality")}
                        name="city"
                        options={cities}
                        onChange={(city) => {
                          setFieldValue("city", city);
                        }}
                        value={values.city.id ? values.city : null}
                        placeholder={t("address:select.selectMunicipality")}
                        isDisabled={!cities.length}
                        required={true}
                      />
                    </Col>
                    <Col md={6}>
                      <FieldInput
                        label={t("organizations:modalCreate.zipCode")}
                        name="zipCode"
                        type="number"
                        placeholder={t("organizations:modalCreate.zipCode")}
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 5);
                        }}
                      />
                    </Col>
                  </Row>
                  {/* address */}
                  <Row>
                    <Col md={12}>
                      <FieldInput
                        label={t("organizations:modalCreate.address")}
                        name="address"
                        type="text"
                        placeholder={t("organizations:modalCreate.address")}
                      />
                    </Col>
                  </Row>

                  <FormLabel className="mt-2">
                    {t("organizations:modalCreate.socialNetworkingInformation")}
                  </FormLabel>
                  {/* facebook & twitter */}
                  <Row>
                    <Col md={6}>
                      <FieldInput
                        label="Facebook"
                        name="facebook"
                        type="text"
                        placeholder="https://wwww.facebook.com/"
                      />
                    </Col>
                    <Col md={6}>
                      <FieldInput
                        label="Twitter"
                        name="twitter"
                        type="text"
                        placeholder="https://wwww.twitter.com/"
                      />
                    </Col>
                  </Row>

                  <FormLabel className="mt-2">
                    {t("organizations:modalCreate.advancedInformation")}
                  </FormLabel>
                  {/* googleAnalyticsViewID & googleServices */}
                  <Row>
                    <Col md={6}>
                      <FieldInput
                        label={t(
                          "organizations:modalCreate.googleAnalyticsViewID"
                        )}
                        name="google_analytics_id"
                        type="text"
                        placeholder={t(
                          "organizations:modalCreate.googleAnalyticsViewID"
                        )}
                      />
                    </Col>
                    <Col md={6}>
                      <FormSelect
                        label={t("organizations:modalCreate.googleServices")}
                        name="google_for_education"
                        options={[
                          {
                            label: t("organizations:modalCreate.active"),
                            value: 1,
                          },
                          {
                            label: t("organizations:modalCreate.inactive"),
                            value: 0,
                          },
                        ]}
                        onChange={(event) => {
                          setFieldValue("google_for_education", event);
                        }}
                        value={values.google_for_education}
                        placeholder={t(
                          "organizations:modalCreate.googleServices"
                        )}
                      />
                    </Col>
                  </Row>
                  {/* firebaseProject  & language */}
                  <Row>
                    <Col md={6}>
                      <FieldInput
                        label={t("organizations:modalCreate.firebaseProject")}
                        name="firebase_project_name"
                        type="text"
                        placeholder={t(
                          "organizations:modalCreate.firebaseProject"
                        )}
                      />
                    </Col>
                    <Col md={6}>
                      <FormSelect
                        label={t("organizations:modalCreate.language")}
                        name="language"
                        options={[
                          {
                            label: t("organizations:modalCreate.english"),
                            value: "english",
                          },
                          {
                            label: t("organizations:modalCreate.spanish"),
                            value: "spanish",
                          },
                        ]}
                        onChange={(event) => {
                          setFieldValue("language", event);
                        }}
                        value={values.language}
                        placeholder={t("organizations:modalCreate.language")}
                      />
                    </Col>
                  </Row>

                  {/* services */}
                  <FormLabel className="mt-2">
                    {t("organizations:modalCreate.organizationServices")}
                  </FormLabel>
                  <Row>
                    {organizationServices.map((item) => {
                      return (
                        <Col md={4} sm={6} xs={12} key={item.id}>
                          <FormCheck
                            name={item.name}
                            label={item.label}
                            type="checkbox"
                          />
                        </Col>
                      );
                    })}
                  </Row>
                  <Row className="mt-3">
                    <Col md={12}>
                      <FormCheck
                        name="testing"
                        label={t("organizations:modalCreate.organizationTest")}
                        type="checkbox"
                        // showRequired={isRequired}
                      />
                    </Col>
                  </Row>
                  {serviceErrors && (
                    <Row>
                      <Col md={12}>
                        <Alert variant="warning">{serviceErrors}</Alert>
                      </Col>
                    </Row>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <ContentButton className="content-button">
                    <Button
                      variant="outline-secondary"
                      onClick={() =>
                        props.setShowModalCreateOrganization(false)
                      }
                      disabled={loadingAction}
                    >
                      {t("global:buttons.cancel")}
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={loadingAction}
                    >
                      {loadingAction ? (
                        <TextLoading
                          text={t("global:buttons.saving")}
                          variant="light"
                        />
                      ) : (
                        t("global:buttons.create")
                      )}
                    </Button>
                  </ContentButton>
                </Modal.Footer>
              </Form>
            </Fragment>
          )}
        </Formik>
      </Modal>
    </div>
  );
}

ModalCreateOrganization.propTypes = {
  showModalCreateOrganization: PropTypes.bool,
  setShowModalCreateOrganization: PropTypes.func,
  updateList: PropTypes.func,
};
