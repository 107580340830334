// Libraries
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import firebase from "../firebase/firebase";
import * as Sentry from "@sentry/react";

// Custom Components
import {
  GetNotificationToken,
  SetNotificationToken,
  GetOrganizationNotification,
  SetOrganizationNotification,
  GetUserNotification,
  SetUserNotification,
} from "./global/GlobalTools";

// API Services
import { SaveDeviceToken, deleteDeviceToken } from "../api/Session";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const PushNotifications = (props) => {
  const { apiKey = "", getNotifications = () => {}, organizationId } = props;
  const prefix = process.env.REACT_APP_PREFIX;
  const isSupported = () =>
    "Notification" in window &&
    "serviceWorker" in navigator &&
    "PushManager" in window;

  // Initial Values
  useEffect(() => {
    Sentry.setTag("section", "Push Notifications");
    if (!isSupported) return;
    // Request permission for notifications if access is set by default
    if (window.Notification && window.Notification.permission === "default") {
      window.Notification.requestPermission();
    }
    // Load Service Worked
    if (firebase.messaging.isSupported() && apiKey) {
      loadServiceWorked(apiKey, organizationId);
    }
  }, [apiKey]);

  // Functions

  /**
   * Puporse: Load Service Worked
   * @param {String} apiKey
   * @param {int} organizationId
   */
  const loadServiceWorked = (apiKey, organizationId) => {
    const messaging = getMessaging();
    // load the notification listener in the foreground
    onMessage(messaging, ({ data }) => {
      getNotifications();
      // Custom options
      const message = data.type_user
        ? `${data.type_user} | ${data.body}`
        : data.body;
      const notificationOption = {
        body: message,
      };
      //add onClick event to notification
      if (window.Notification?.permission === "granted") {
        const notification = new window.Notification(
          data.title,
          notificationOption
        );
        notification.onclick = function (ev) {
          ev.preventDefault();
          window.location.replace(data.link);
          notification.close();
        };
      }
    });
    // check if a new firebase token is required
    let exist = GetNotificationToken();
    const notificationToken = exist;
    // Validate token
    exist =
      exist && typeof exist == "string" && exist !== "false" ? true : false;
    // Get Organization Cookie
    const notificacionOrganization = GetOrganizationNotification();
    // Validate Notification organization
    const existOrg =
      notificacionOrganization &&
      typeof notificacionOrganization == "string" &&
      notificacionOrganization !== "false"
        ? true
        : false;
    // Get User Token Notifiaction
    const userNotification = GetUserNotification();
    const userID = localStorage.getItem(`cmUserID${prefix}`);
    const validateUsernotification =
      userNotification &&
      typeof userNotification == "string" &&
      userNotification != "false"
        ? true
        : false;
    if (window.Notification && window.Notification.permission !== "granted") {
      SetNotificationToken(false);
      SetOrganizationNotification(false);
      SetUserNotification(false);
      exist = false;
    }
    // Validate organization notification
    if (
      exist &&
      existOrg &&
      validateUsernotification &&
      (userNotification !== userID ||
        notificacionOrganization !== organizationId) &&
      window.Notification?.permission === "granted"
    ) {
      firebase
        .messaging()
        .deleteToken()
        .then(() => {
          // Delete device
          deleteDeviceToken(notificationToken)
            .then(() => {
              SetNotificationToken(false);
              SetOrganizationNotification(false);
              SetUserNotification(false);
              exist = false;
            })
            .catch((error) => {
              SetNotificationToken(false);
              SetOrganizationNotification(false);
              SetUserNotification(false);
              exist = false;
              Sentry.captureException(Error(JSON.stringify(error)));
            });
        })
        .then(() => {
          const vapidKey =
            "BPShKEqYpGKCcIrcIZDTn3v7zq77tl1JWKI1VB_ufFKIE6tYbrK52E0iwubgCycnaRsp88LjW0MYao0BCi9ewIs";
          getToken(messaging, { vapidKey })
            .then((token) => {
              const data = {
                api_key: apiKey,
                user_id: localStorage.getItem(`cmUserID${prefix}`),
                type: "web",
                device_token: token,
              };
              // Save Device token
              SaveDeviceToken(data)
                .onSuccess(
                  function () {
                    SetNotificationToken(token);
                    SetOrganizationNotification(organizationId);
                    SetUserNotification(userID);
                  }.bind(this)
                )
                .onError(
                  function (xhr) {
                    if (
                      xhr.responseJSON.error.message ===
                        "El token del dispositivo ya está registrado" ||
                      xhr.responseJSON.error.message ===
                        "Device token is already registered"
                    ) {
                      SetNotificationToken(token);
                      SetOrganizationNotification(organizationId);
                      SetUserNotification(userID);
                      Sentry.captureException(Error(JSON.stringify(xhr)));
                    }
                  }.bind(this)
                )
                .onComplete(function () {});
            })
            .catch((error) => {
              SetNotificationToken(false);
              SetOrganizationNotification(false);
              SetUserNotification(false);
              Sentry.captureException(Error(JSON.stringify(error)));
            });
        })
        .catch((error) => {
          SetNotificationToken(false);
          SetOrganizationNotification(false);
          SetUserNotification(false);
          Sentry.captureException(Error(JSON.stringify(error)));
        });
    } else if (!exist && window.Notification?.permission === "granted") {
      const vapidKey =
        "BPShKEqYpGKCcIrcIZDTn3v7zq77tl1JWKI1VB_ufFKIE6tYbrK52E0iwubgCycnaRsp88LjW0MYao0BCi9ewIs";
      getToken(messaging, { vapidKey })
        .then((token) => {
          const device = {
            api_key: apiKey,
            user_id: localStorage.getItem(`cmUserID${prefix}`),
            type: "web",
            device_token: token,
          };
          SaveDeviceToken(device)
            .then(() => {
              SetNotificationToken(token);
              SetOrganizationNotification(organizationId);
              SetUserNotification(userID);
            })
            .catch((error) => {
              SetNotificationToken(token);
              SetOrganizationNotification(organizationId);
              SetUserNotification(userID);
              Sentry.captureException(Error(JSON.stringify(error)));
            });
        })
        .catch(() => {
          loadServiceWorked(apiKey, organizationId);
        });
    }
  };

  // Return notifiaction view
  return <></>;
};

export default PushNotifications;

PushNotifications.propTypes = {
  apiKey: PropTypes.string,
  getNotifications: PropTypes.func,
  organizationId: PropTypes.string,
};
