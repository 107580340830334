import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Button, Dropdown } from "react-bootstrap";
import ModalCreateStaff from "./modals/ModalCreateStaff";
import ContentTable from "../../components/table/ContentTable";
import InputFilter from "../../components/table/InputFilter";
import SelectFilter from "../../components/table/SelectFilter";
import { Table } from "../../components/table/Table";
import { GetUsers } from "../../api/Staff";
import {
  getUrlParamValue,
  goToHref,
} from "../../components/global/GlobalTools";
import ContentSolid from "../../components/global/ContentSolid";
import { LoadingTable } from "../../components/lazyLoading/LazyLoading";
import ExcellentExport from "excellentexport";
import ReactToPrint from "react-to-print";
import ModalInactive from "./modals/ModalInactive";
import ModalImportStaff from "../../components/staff/ModalImportStaff";
import ModalImportResult from "./modals/ModalImportResult";
import * as Sentry from "@sentry/react";
import PropTypes from "prop-types";

const customStyles = {
  textDangerBold: {
    color: "#ea2c54",
    fontWeight: "bold",
    fontSize: "small",
  },
  textGrayBold: {
    color: "#c8cbcc",
    fontWeight: "bold",
    fontSize: "small",
    marginLeft: "10px",
  },
};

/**Get param to open import staff modal */
const importStaff = getUrlParamValue("importStaff");
/**Get param to open import staff modal */
const createStaff = getUrlParamValue("createStaff");
const StaffListView = () => {
  const [t] = useTranslation([
    "staff",
    "global",
    "crm",
    "modalCreateStudent",
    "reports",
  ]);
  const [showModalStaff, setShowModalStaff] = useState(createStaff);
  const [dataTable, setDataTable] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingView, setLoadingView] = useState(true);
  const [selectAllRows, setSelectAllRows] = useState({
    switch: false,
    value: false,
  });
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [isAllRowsSelected, setIsAllRowsSelected] = useState(false);
  const [rowIsSelect, setRowIsSelect] = useState(false);
  //Options to export to excel
  const exportOptions = {
    openAsDownload: true,
    format: "xlsx", //'xlsx' or 'xls' or 'csv'
    filename: t("modalStaff.titleDowload"),
  };
  const componentRef = useRef(); //Create the reference for printing
  const [exportData, setExportData] = useState([]);
  const [printExport, setPrintExport] = useState(true);
  const [counterSelectedStaff, setCounterSelectedStaff] = useState(0);
  const [disabledBtnInactive, setDisabledBtnInactive] = useState(true);
  const [showModalInactive, setShowModalInactive] = useState(false);
  const [showImportStaff, setShowImportStaff] = useState(importStaff);
  const [showImportResult, setShowImportResult] = useState(false);
  const [importResult, setImportResult] = useState([]);
  const [count, setCount] = useState(0);

  /**
   * Stores the table column data in memory, and is not updated unless useMemo identifies a change in the data.
   */
  const columns = React.useMemo(
    () => [
      {
        Header: t("crm:tableCrm.name"),
        accessor: "name",
        Filter: (props) => <InputFilter {...props} />,
        width: 500,
      },
      {
        Header: t("Roles"),
        accessor: "roles",
        Filter: (props) => <SelectFilter {...props} />,
        width: 450,
        Cell: ({ cell: { value: Roles } }) => {
          return (
            <td style={{ wordBreak: "break-word", width: "100%" }}>
              {Roles.join(", ")}
            </td>
          );
        },
      },
      {
        Header: t("modalCreateStudent:labels.email"),
        accessor: "email",
        Filter: (props) => <InputFilter {...props} />,
        width: 600,
      },
    ],
    [loading]
  );

  /**
   * Get the list of all users
   */
  const listStaff = () => {
    setLoading(true);
    GetUsers().then((result) => {
      if (result && result.description) {
        Sentry.captureException(Error(JSON.stringify(result)));
        setError(result.description);
      } else {
        let data = result.data;
        let userRole = [];
        userRole = data.map((item) => {
          return {
            index: item.id,
            id: item.id,
            linkToPage: `${process.env.REACT_APP_V1}/staff/${item.id}`,
            name: item.name,
            email: item.email || "N/A",
            groupId: item.groups[0].id,
            roles: formatRoles(item),
          };
        });
        setDataTable(userRole);
        setDisabledBtnInactive(true);
      }
      setLoading(false);
      setLoadingView(false);
    });
  };

  /**
   *Function to format roles that have 2
   * @param {Obj} item
   * @returns
   */
  const formatRoles = (item) => {
    return item.groups.map((item) => {
      return item.name;
    });
  };

  /**
   * Redirect to version 1
   * @param {String} profile
   */
  const goApp1 = (profile) => {
    var now = new Date();
    now.setDate(now.getDate() + 5);
    goToHref(`/staff/${profile}`);
  };

  /**
   * Select the entire row and enter the profile redirecting to version 1
   * @param {obj} user //Row information
   */
  const rowOnclick = (user) => {
    const path = user.groupId != 7 ? user.id + "&" + user.groupId : user.id;
    goApp1(path);
  };

  /**
   * Effect of knowing if all rows are selected
   */
  useEffect(() => {
    let selectAll = {
      switch: selectAllRows.switch,
      value: isAllRowsSelected,
    };
    setSelectAllRows(selectAll);
  }, [selectedRowIds]);

  /**
   * Initial loading
   */
  useEffect(() => {
    Sentry.setTag("section", "Staff List");
    listStaff();
  }, []);

  //Saves the data for the table in memory, and is not updated unless useMemo identifies a change in the data
  const data = React.useMemo(() => dataTable, [loading]);

  const rowSelect = (value) => {
    setRowIsSelect(value);
  };

  /**
   * Stock options for PDF or Excel downloading
   */
  const actionOptions = [
    { value: "list", label: "reports:actionOptions.downloadPDF" },
    { value: "excel", label: "reports:actionOptions.downloadExcel" },
  ];

  //Export to Excel
  const downloadFile = (exportOptions, exportData) => {
    ExcellentExport.convert(exportOptions, exportData);
  };

  /**
   * Purpose: Toggle for the "Import Staff" modal
   */
  const toggleImportStaff = () => {
    setShowImportStaff(true);
  };

  return (
    <Container fluid>
      <Row>
        <Col md={12} lg={2}>
          <h2>{t("staff.staff")}</h2>
        </Col>
      </Row>

      {/** Section for print, export, modal buttons */}
      <Row style={{ marginBottom: "20px", marginTop: "20px" }}>
        <Col lg={6}>
          <div>
            <Button
              variant="outline-secondary"
              onClick={() => {
                let selectAll = {
                  switch: !selectAllRows.switch,
                  value: !selectAllRows.value,
                };
                setSelectAllRows(selectAll);
              }}
              style={{ marginRight: "10px" }}
            >
              {!isAllRowsSelected
                ? t("global:buttons.selectAll")
                : t("global:buttons.unselectAll")}
            </Button>
            <Button
              variant="primary"
              disabled={counterSelectedStaff > 0 ? false : disabledBtnInactive}
              onClick={() => setShowModalInactive(true)}
            >
              {t("global:buttons.inactivate")}
            </Button>
            {/* Total counter of records and selected records in Staff table */}
            {selectedRowIds.length ? (
              selectedRowIds.length == 1 ? (
                <p
                  className="ms-3 d-inline"
                  style={customStyles.textDangerBold}
                >
                  {t("table:textTableViews.selectedRecord", {
                    countSelected: selectedRowIds.length,
                  })}
                </p>
              ) : (
                <p
                  className="ms-3 d-inline"
                  style={customStyles.textDangerBold}
                >
                  {t("table:textTableViews.selectedRecords", {
                    countSelected: selectedRowIds.length,
                  })}
                </p>
              )
            ) : (
              <p className="ms-3 d-inline" style={customStyles.textGrayBold}>
                {t("table:textTableViews.totalRecords", {
                  count: count,
                })}
              </p>
            )}
          </div>
        </Col>

        <Col lg={6}>
          <div style={{ paddingTop: "5px", float: "right" }}>
            <Dropdown style={{ display: "inline-block", marginRight: "10px" }}>
              <Dropdown.Toggle
                id="downloadOptionStaff"
                variant="outline-secondary"
                disabled={printExport}
              >
                <i className="bi bi-download"></i>
                {rowIsSelect
                  ? t("global:buttons.selectTable")
                  : t("global:buttons.download")}
              </Dropdown.Toggle>
              <Dropdown.Menu disabled={printExport}>
                {actionOptions.map((action) => {
                  return action.value == "list" ? (
                    <ReactToPrint
                      trigger={() => (
                        <Dropdown.Item>{t(action.label)}</Dropdown.Item>
                      )}
                      content={() => componentRef.current}
                    />
                  ) : (
                    <ReactToPrint
                      trigger={() => (
                        <Dropdown.Item>{t(action.label)}</Dropdown.Item>
                      )}
                      content={() => downloadFile(exportOptions, exportData)}
                    />
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            <a
              href="?importStaff=true"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <Button
                variant="outline-secondary"
                onClick={() => toggleImportStaff(true)}
                style={{ marginRight: "10px" }}
              >
                {t("global:buttons.import")}
              </Button>
            </a>
            <a
              href="?createStaff=true"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <Button variant="primary" onClick={() => setShowModalStaff(true)}>
                <i className="bi bi-person-plus-fill"></i>
                {t("staff.btnCreateStaff")}
              </Button>
            </a>
          </div>
        </Col>
      </Row>

      {/* TABLA */}
      {loadingView ? (
        <ContentSolid style={{ marginTop: "20px" }}>
          <LoadingTable />
        </ContentSolid>
      ) : (
        <>
          {error}
          <ContentTable startColumFilter={1} lastColumFilter={4}>
            <Table
              data={data}
              columns={columns}
              rowSelect={true}
              loading={loading}
              ref={componentRef}
              rowOnclick={rowOnclick}
              rowIsSelect={rowSelect}
              selectAllRows={selectAllRows}
              setExportData={setExportData}
              setPrintExport={setPrintExport}
              setSelectedRowIds={setSelectedRowIds}
              setIsAllRowsSelected={setIsAllRowsSelected}
              setCounterSelectedStudents={setCounterSelectedStaff}
              isLinked={true}
              setRowsDisplayed={setCount}
            />
          </ContentTable>
        </>
      )}
      {showModalStaff && (
        <ModalCreateStaff
          showModalStaff={showModalStaff}
          setShowModalStaff={setShowModalStaff}
          listStaff={listStaff}
        />
      )}
      <ModalInactive
        showModalInactive={showModalInactive}
        setShowModalInactive={setShowModalInactive}
        selectedRowIds={selectedRowIds}
        listStaff={listStaff}
      />
      <ModalImportStaff
        showImportStaff={showImportStaff}
        setShowImportStaff={setShowImportStaff}
        setShowImportResult={setShowImportResult}
        setImportResult={setImportResult}
        listStaff={listStaff}
      />
      <ModalImportResult
        showImportResult={showImportResult}
        importResult={importResult}
        setShowImportResult={setShowImportResult}
      />
    </Container>
  );
};

export default StaffListView;
StaffListView.propTypes = {
  cell: PropTypes.object,
};