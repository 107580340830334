/**
 *       **** Readme ****
 *
 * Component: <FormGender />
 * Purpose: Shows the radio inputs for Gender.
 *
 * Props:
 *   -required: {Boolean} Indicates if the field is required
 *   -showRequired: {Boolean} Indicates whether the "*" or the "optional" text is displayed
 *
 *
 * Creation date: 01/March/2022
 * Last update: 04/April/2022
 */

import React from "react";
import { useField, useFormikContext } from "formik";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export default function FormGender({ label, gender, ...props }) {
  const [t] = useTranslation(["global"]);
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  return (
    <Form>
      <Form.Label
        data-text={t("optional")}
        htmlFor={props.id || props.name}
        className={
          props.required && props.showRequired
            ? "required"
            : !props.required && props.showRequired === false
            ? "optional"
            : ""
        }
      >
        {label}
      </Form.Label>
      <br />
      <div className="mb-3">
        <Form.Check
          inline
          label={gender.male}
          name={props.name}
          id="genderMale"
          type="radio"
          value="male"
          style={{ height: "20px", width: "100px" }}
          {...field}
          {...props}
          onChange={() => {
            setFieldValue(field.name, "male");
          }}
        />
        <Form.Check
          inline
          label={gender.female}
          name={props.name}
          id="genderFemale"
          type="radio"
          value="female"
          style={{ height: "20px", width: "20px" }}
          {...field}
          {...props}
          onChange={() => {
            setFieldValue(field.name, "female");
          }}
        />
      </div>
    </Form>
  );
}

FormGender.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  gender: PropTypes.object,
  required: PropTypes.bool,
  showRequired: PropTypes.bool,
};
