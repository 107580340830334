import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal, Button } from "react-bootstrap";
import { DeleteDevelopmentAreas } from "../../../api/developmentArea";
import TextLoading from "../../global/TextLoading";

export default function DeleteDevelopmentArea(props) {
  const [t] = useTranslation(["global", "formativeFields"]);
  const [saving, setSaving] = useState(false);
  const [itemsForDelete, setItemsForDelete] = useState([]);

  /**
   * Delete items
   */
  const deleteItems = () => {
    DeleteDevelopmentAreas({ ids: itemsForDelete })
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        setSaving(false);
        props.updateTableInfo();
        props.setShowDeleteDevelopmentArea(false);
      });
  };

  useEffect(() => {
    if (props.selectedRowIds[0].original) {
      setItemsForDelete(props.selectedRowIds.map((news) => news.original.id));
    } else {
      setItemsForDelete(props.selectedRowIds);
    }
  }, []);

  return (
    <Modal
      show={props.showDeleteDevelopmentArea}
      bsSize="large"
      aria-labelledby="contained-modal-title-lg"
    >
      <Modal.Header>
        <Modal.Title className="modal-title">
          {t("formativeFields:modals.titleEliminate")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{t("formativeFields:modals.menssageEliminate")}</Modal.Body>
      <Modal.Footer>
        <div>
          <Button
            variant="outline-secondary"
            onClick={() => props.setShowDeleteDevelopmentArea(false)}
            disabled={saving}
          >
            {t("global:buttons.cancel")}
          </Button>
          <Button
            type="submit"
            disabled={saving}
            onClick={() => {
              setSaving(true);
              deleteItems();
            }}
          >
            {saving ? (
              <TextLoading
                text={t("global:buttons.eliminating")}
                variant="light"
              />
            ) : (
              t("global:buttons.eliminate")
            )}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

DeleteDevelopmentArea.propTypes = {
  showDeleteDevelopmentArea: PropTypes.bool, //Required
  setShowDeleteDevelopmentArea: PropTypes.func, //Required
  updateTableInfo: PropTypes.func,
  selectedRowIds: PropTypes.array,
};
